const config = {
    apiGateway: {
      REGION: "ca-central-1",
      URL: window.location.host === 'mybusiness.saskpension.com' ? "https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod" : window.location.host === 'test.mybusiness.saskpension.com' ? "https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/test" : "https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/dev",
    },
    cognito: {
      REGION: "ca-central-1",
      USER_POOL_ID: "ca-central-1_BffKCCoW3",
      APP_CLIENT_ID: "3fq36r4luil8717nts38fh7nqs",
      IDENTITY_POOL_ID: "ca-central-1:804eff3a-031e-4460-a9a1-34085b1f6c82",
    },
    storage: {
        BUCKET: window.location.host === 'mybusiness.saskpension.com' ? "files.mybusiness.saskpension.com":
                                           window.location.host === 'test.mybusiness.saskpension.com' ? "files-test.mybusiness.saskpension.com":
                                           "files-dev.mybusiness.saskpension.com",
        REGION: "ca-central-1",
    },
    timer: {
      //in minutes
      idleTimout: 18,
      prompt: 2
    }
  };
  
  export default config;