import React, {useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
// import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import config from '../config';

export default function ChangeEmployer(props) {

  // const [open, setOpen] = React.useState(false);
  const [newEmployerID, setNewEmployer] = React.useState(null);
  const [employers, setEmployers] = React.useState([]);
  const [isloaded, setIsLoaded] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [employerStyle, setEmployerStyle] = React.useState([]);
  // const [searchText, setSearchText] = React.useState('');
  // const [inputValue, setInputValue] = React.useState('');


  const handleClickOpen = async () => {
    if (!isloaded) {
      let user = await Auth.currentUserInfo();
      let token = await Auth.currentSession().then(res => res.getIdToken());
      // console.log(token);

      if (user !== null) {
        let employerResp = await fetch(config.apiGateway.URL + '/ers/' + user.username + '/employers/?q=' + encodeURIComponent('{"STATUS":{"$eq":"A"}}'), { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
          .then(response => response.json());

        // console.log(employerResp);

        // if (employerResp.items !== undefined && employerResp.items.length > 0) {
          
        //   // setEmployers(employerResp.items);

        //   for(let i = 0; i < employerResp.items.length; i++){
        //     await fetch('https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod/ers/' + user.username + '/' + employerResp.items[i].employerid + '/employer', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
        //     .then(response => response.json())
        //   .then(json => {
        //     // console.log(json)
        //     employerResp.items[i].employerName = json.items[0].surname
        //   })
        //   }
          setEmployers(employerResp.items);
        // }

        setIsLoaded(true);
      }
    }
  };

  useEffect(() => {
    handleClickOpen();
  }, [props.employerid]);

  useEffect(() => {
    setNewEmployer(props.toEmployer)
  }, [props.toEmployer]);

  // useEffect(() => {
    // setSearchText(props.searchText)
    // console.log('useEffect')
  // }, [props.searchText]);

  // const updateEmployer = (event) => {
  //   console.log(event);
  //   console.log(event.target.value);
  //   console.log(event.target.dataset.optionIndex)
  //   if (isloaded && event.target.dataset.optionIndex !== undefined) {
  //   setNewEmployer(employers[event.target.dataset.optionIndex].employerid);
  //   props.setEmployerid(employers[event.target.dataset.optionIndex].employerid);
  //   // console.log(employers[event.target.dataset.optionIndex].employerid)
  // }
  // }

  const updateEmployer = (employerid) => {
    // console.log(employerid);
    if (isloaded && employerid !== undefined) {
    setNewEmployer(employerid);
    props.setEmployerid(employerid);
    }
  }

  // let employersList = employers.map((employer) => {
  
  //   return (
  //     <option key={employer.employerid} value={employer.employerid}>{employer.employerid}, {employer.employerName}</option>
  //   )
  // }, this);

  const handleClose = () => {
    // setOpen(false);
    // window.location.reload(false);
  };

  
  
  const handleConfirm = async () => {
    let token = await Auth.currentSession().then(res => res.getIdToken());
    props.employerid.setEmployerid(newEmployerID);
    // setOpen(false);
  };
  // employerid={{ employerid, setEmployerid }} style={{maxHeight: "42px", marginLeft:"10px"}}
  return (
    // <div onClick={handleClickOpen}>
    <div class="input" style={{ marginLeft: "10px", height: "42px" }}>
      {/* {props.searchText} */}
      <Autocomplete
      

        options={employers}
        renderOption={(props,option, state) => (
          <div style={{ color: option.employer_status === 'I' ? 'crimson' : '#6c9e32' }} {...props}>
            {option.surname + ' ' + option.employerid}
          </div>
        )}
        getOptionLabel={(option) => option.surname + ' ' + option.employerid}
    
        onChange={(event, newValue) => {
          if (newValue !== null) {
            updateEmployer(newValue.employerid);
          }
        }}
        inputValue={props.searchText}
        onInputChange={(event, newInputValue) => {
          props.setSearchText(newInputValue);
        }}
        // value={newEmployerID}
        // id="open-on-focus"
        style={{ width: 300, backgroundColor: "white", marginTop: "0px", height: "42px" }}
        // searchText={searchText}
        openOnFocus
        renderInput={(params) => <div ref={params.InputProps.ref}>
          {/* <p>{props.searchText}</p> */}
          <input style={{ width: 300, height: "42px" }} placeholder="Change Employer" type="text" {...params.inputProps} />
        </div>}
      />
      {/* <select onChange={updateEmployer} value={newEmployerID}>
            <option value="0">Change Employer</option>
            {employersList}
            </select> */}
    </div>
          // </div>
  );
}