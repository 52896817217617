import React, { Component } from 'react';
//import queryString from 'query-string';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import config from '../config';

class VerifyAuthority extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employer_num: '',
      employername: '',
      verify_code: '',
      email: '',
      reference: '',
      hasVerified: false,
    };
    this.verifyEmail = this.verifyEmail.bind(this);
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  componentDidMount() {
    //const windowUrl = window.location.search;
    //const params = new queryString.parse(windowUrl);
    const params = new URLSearchParams(window.location.search);

    // console.log(params);
    if (params.get('employerid') !== undefined) {
      this.setState({ employer_num: params.get('employerid') })
    }
    if (params.get('email') !== undefined) {
      this.setState({ email: params.get('email') })
    }
    if (params.get('code') !== undefined) {
      this.setState({ verify_code: params.get('code') })
    }
    if (params.get('employername') !== undefined) {
      this.setState({ employername: params.get('employername') })
    }
    if (params.get('employerauthorityid') !== undefined) {
      this.setState({ reference: params.get('employerauthorityid') })
    }
  }

  async verifyEmail() {

    let postBody = JSON.stringify({
      "employerid": this.state.employer_num,
      "code": this.state.verify_code,
      "reference": this.state.reference,
    });

    let response = await fetch(config.apiGateway.URL + '/verifyAuthority', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
    .then(response => response.json());

    if(response.result === 0){
    this.setState({hasVerified: true});
  }
  }
  handleClose = (event) => {
    this.setState({hasVerified: false});
    window.location.href = 'https://www.saskpension.com/'
  };

  render() {
    return (
      //<!-- HEADER NAVIGATION -->	
      <div class="title">
<Snackbar open={this.state.hasVerified} onClose={this.handleClose}>
                <SnackbarContent style={{
                                backgroundColor:'teal',
                              }}
                              message={<span id="client-snackbar">Email Verified</span>}
                              action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
                            />
      </Snackbar>
        <div class="row">

          <div class="column col-6 my_spp_auth_form">
            <h2>Verify Authority</h2>
            <form method="POST" class="border-form spin-on-submit">
              <div class="input">
                <label for="employer_num" class="bold-label ">Employer ID:</label>
                <input type="number" onWheel={(e) => e.target.blur()} name="employer_num" class="wide-input" readOnly={true} value={this.state.employer_num} autocomplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input">
                <label for="employername" class="bold-label ">Employer Name:</label>
                <input type="text" name="employername" class="wide-input" readOnly={true} value={this.state.employername} autocomplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input">
                <label for="email" class="bold-label ">Email Address:</label>
                <input type="text" name="email" readOnly={true} class="wide-input" value={this.state.email} autocomplete="email" onChange={this.myChangeHandler} />
              </div>
              {/* <div class="input required">
                    <label for="verify_code" class="bold-label ">Verification Code:</label>
                    <input type="text" name="verify_code" class="wide-input" value={this.state.verify_code} autocomplete="email" onChange={this.myChangeHandler} />
                                      </div> */}
              <label class="bold-label ">Click Verify to confim that you are a signing authority for this employer, and that your email address has been entered correctly</label>
              <br />
              <div>
                <button type="button" name="verify" onClick={this.verifyEmail} class="button-green">Verify</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    );
  }
}

export default VerifyAuthority