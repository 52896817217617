
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { AppContext } from "./libs/contextLib";
import { AdminContext } from "./libs/adminLib";
import { onError } from "./libs/errorLib";
// import { useIdleTimer } from 'react-idle-timer'
import SessionEnded from "./Components/SessionEnded";
import Login from './Components/Login';
import RegisterExisting from './Components/RegisterExisting';
import VerifyAuthority from './Components/VerifyAuthority';
import HeaderMenu from './Components/HeaderMenu';
import Header from './Components/Header';
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import AdminRoute from "./Components/AdminRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";
import config from "./config";
import ForgotPassword from "./Components/ForgotPassword";
import EmployerRegister from "./Components/EmployerRegister";


const MessageMenu = lazy(() => import('./Components/MessageMenu'));
const Remit = lazy(() => import('./Components/Remit'));
const AdminRemitReport = lazy(() => import("./Components/AdminRemitReport"));
const PayrollProvider = lazy(() => import("./Components/PayrollProvider"));
const AddEmployer = lazy(() => import("./Components/AddEmployer"));
const Documents = lazy(() => import("./Components/Documents"));
const Notes = lazy(() => import("./Components/Notes"));

const Maintenance = lazy(() => import("./Components/Maintenance"));
const AdminDashboard = lazy(() => import("./Components/AdminDashboard"));
const AdministratorProfile = lazy(() => import('./Components/AdministratorProfile'));
const IncompleteRegistration = lazy(() => import('./Components/IncompleteRegistration'));
const IncompleteRegContrib = lazy(() => import('./Components/IncompleteRegContrib'));
const IncompleteEmployees = lazy(() => import('./Components/IncompleteEmployees'));
const ManageEmployees = lazy(() => import('./Components/ManageEmployees'));
// const EmployerRegister = lazy(() => import('./Components/EmployerRegister'));
const EmployerProfile = lazy(() => import('./Components/EmployerProfile'));
const ContributionProfile = lazy(() => import('./Components/ContributionProfile'));
const AdjustmentHoldingTank = lazy(() => import('./Components/AdjustmentHoldingTank'));


function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [employerid, setEmployerid] = useStickyState('', 'employerid');
  const [isAdmin, setAdmin] = useStickyState(false, 'isAdmin');
  const [unreadSM, setunreadSM] = useState(0);
  const [asAdmin, setAsAdmin] = useStickyState(false, 'asAdmin');
  const [isLoaded, setIsLoaded] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showNotice, setShowNotice] = useState(false);


  useEffect(() => {
    getMessage();
 


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  

  async function getMessage() {
    let finished = false;
    let noticeS3 = null;
    for (let i = 0; i < 10 && !finished; i++) {
      noticeS3 = await fetch(config.apiGateway.URL + '/getNoticeMessage', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.ok) {
            finished = true;
            return response.json();
          } else {
            setShowMessage(false);
            setShowNotice(false);
          }
        }).catch((error) => {
          console.log(error)
        });
    }
    // .then(response => response.json());
    // let noticeS3 = noticeS3Call.json();
    if (noticeS3 !== undefined) {
      console.log(noticeS3);
      setMessage(noticeS3.notice);
      let curdate = new Date();
      let startdate = new Date(noticeS3.noticestart);
      let enddate = new Date(noticeS3.noticeend);
     
      let isShowMessage = curdate.getTime() > startdate.getTime() && curdate.getTime() < enddate.getTime()
      if (window.location.host === 'mybusiness.saskpension.com') {
        setShowMessage(isShowMessage);
        setShowNotice(isShowMessage);
      }
      if (!isShowMessage) {
        if (curdate.getTime() < enddate.getTime()) {
          setShowNotice(true);
        }
        onLoad();
      }
      else {
        setLoading(false);
      }
    } else {
      setShowMessage(false);
      setShowNotice(false);
      onLoad();
      setLoading(false);
    }
  }

  async function onLoad() {
    try {
      let curUser = await Auth.currentSession().then(res => res.getAccessToken());
      if (window.sessionStorage.getItem("curState") === null) {
        await Auth.signOut();
      }
    }
    catch {

    }
    window.sessionStorage.setItem("curState", "true");
    try {
      await Auth.currentSession();

      let response = await Auth.currentSession();
      let access = await response.getAccessToken();
      let admin = 0;
      if (access.payload['cognito:groups'] !== undefined) {
        admin = access.payload['cognito:groups'].indexOf('Admin') + 1;
        console.log(access.payload['cognito:groups'].indexOf('Admin'))
      }

      // console.log(admin);
      if (admin > 0) {
        setAdmin(true);
        // console.log(asAdmin);
        if (asAdmin === false && employerid === '') {
          setAsAdmin(true);
          setEmployerid(1);
        }
      }
      else {
        setAdmin(false);
        setAsAdmin(false);
      }
      // console.log(asAdmin);
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    // setIsAuthenticating(false);
    setLoading(false);
  }



  return (
    <div className="App">
      {!loading && <div>
        {showMessage ? <div>
          <Header showMessage={showMessage} message={message} />
        </div>
          : <div>

            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              <AdminContext.Provider value={{ isAdmin, setAdmin }}>
                <BrowserRouter>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return (
                        <Redirect to="/Login" />
                      )
                    }}
                  />
                  <Route path="/"><Header showMessage={showNotice} message={message} />
                    <UnauthenticatedRoute asAdmin={asAdmin} path="/RegisterExisting"><RegisterExisting /></UnauthenticatedRoute>
                    <UnauthenticatedRoute asAdmin={asAdmin} path="/EmployerRegister"><EmployerRegister userHasAuthenticated={userHasAuthenticated} /></UnauthenticatedRoute>
                    <UnauthenticatedRoute asAdmin={asAdmin} path="/ForgotPassword"><ForgotPassword /></UnauthenticatedRoute>
                    <UnauthenticatedRoute asAdmin={asAdmin} path="/VerifyAuthority"><VerifyAuthority /></UnauthenticatedRoute>
                    <UnauthenticatedRoute asAdmin={asAdmin} path="/SessionEnded"><SessionEnded /></UnauthenticatedRoute>
                    {/* <UnauthenticatedRoute path="/ConfirmSignup"><ConfirmSignup  /></UnauthenticatedRoute> */}
                    <Suspense fallback={<></>}>
                    <Switch>
                      <AuthenticatedRoute path="/EmployerProfile"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <EmployerProfile employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/ManageEmployees"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <ManageEmployees employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/ContributionProfile"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <ContributionProfile isIncomplete={false} employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/AdministratorProfile"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <AdministratorProfile employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/SecureMessaging"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <MessageMenu employerid={employerid} asAdmin={asAdmin} unreadSM={{ unreadSM, setunreadSM }} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/Remit"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <Remit employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>
                      <AuthenticatedRoute path="/Documents"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} header={{ isLoaded, setIsLoaded }} asAdmin={{ asAdmin, setAsAdmin }} unreadSM={{ unreadSM, setunreadSM }} />{isLoaded && <Documents employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} />}</AuthenticatedRoute>

                      <AdminRoute path="/AdminRemitReport"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><AdminRemitReport employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/PayrollProvider"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><PayrollProvider asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/AdminDashboard"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><AdminDashboard asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/AddEmployer"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><AddEmployer asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/Notes"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><Notes employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/Maintenance"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><Maintenance asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                      <AdminRoute path="/AdjustmentHoldingTank"><HeaderMenu employerid={{ employerid, setEmployerid }} isAdmin={isAdmin} asAdmin={{ asAdmin, setAsAdmin }} header={{ isLoaded, setIsLoaded }} unreadSM={{ unreadSM, setunreadSM }} /><AdjustmentHoldingTank asAdmin={asAdmin} isAdmin={isAdmin} /></AdminRoute>
                    </Switch>
                    
                    <AuthenticatedRoute path="/IncompleteRegistration"><IncompleteRegistration employerid={employerid} isAdmin={isAdmin} /></AuthenticatedRoute>
                    <AuthenticatedRoute path="/IncompleteRegContrib"><IncompleteRegContrib employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} /></AuthenticatedRoute>
                    <AuthenticatedRoute path="/IncompleteEmployees"><IncompleteEmployees employerid={employerid} asAdmin={asAdmin} isAdmin={isAdmin} /></AuthenticatedRoute>
                    </Suspense>
                  </Route>
                  <UnauthenticatedRoute asAdmin={asAdmin} setEmployerid={setEmployerid} path="/Login"><Login asAdmin={{ asAdmin, setAsAdmin }} isAdmin={{ isAdmin, setAdmin }} /></UnauthenticatedRoute>
                </BrowserRouter>
              </AdminContext.Provider>
            </AppContext.Provider>
          </div>}
      </div>}
    </div>
  );
}
export default App;
















