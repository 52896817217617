import React, { Component } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Auth } from 'aws-amplify';
import { onError } from "../libs/errorLib";
import PropTypes from "prop-types";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import PostalCodeCheck from './PostalCodeValidator';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Modal } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';
import { Prompt } from 'react-router';
import { withRouter } from "react-router";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ProvinceSelect from './ProvinceSelector';
import { validatePostalCodeAgainstProvince } from './validateHelpers';


const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  // console.log(value);
  // const currentValue = value.replace(/[a-zA-Z]/g, '').replace(/[ ]/g, '').replace(/[-]/g, '');
  const currentValue = value.replace(/\D+/g, "");
  // console.log(currentValue);
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

const normalizePostalCode = (value, previousValue) => {
  // console.log(value);
  // console.log(previousValue);
  if (!value) return value;
  const removespace = value.replace(/ /g, '');
  const currentValue = removespace.slice(0, 6)
  const cvLength = currentValue.length;
  var regex = /[a-zA-Z]/;
  ///[^a-zA-Z0-9]/

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 2) return currentValue.replace(/[^a-zA-Z]/, '');
    if (cvLength < 3) {
      // if(regex.test(currentValue.slice(1)))
      return currentValue[0].replace(/[^a-zA-Z]/, '') + currentValue[1].replace(/[^0-9]/, '');
    }
    if (cvLength < 4) {
      // if(regex.test(currentValue.slice(1)))
      return currentValue[0].replace(/[^a-zA-Z]/, '') + currentValue[1].replace(/[^0-9]/, '') + currentValue[2].replace(/[^a-zA-Z]/, '') + ' ';
    }
    if (cvLength < 5) {
      // if(regex.test(currentValue.slice(1)))
      return currentValue[0].replace(/[^a-zA-Z]/, '') + currentValue[1].replace(/[^0-9]/, '') + currentValue[2].replace(/[^a-zA-Z]/, '') + ' ' + currentValue[3].replace(/[^0-9]/, '');
    }
    if (cvLength < 6) {
      // if(regex.test(currentValue.slice(1)))
      return currentValue[0].replace(/[^a-zA-Z]/, '') + currentValue[1].replace(/[^0-9]/, '') + currentValue[2].replace(/[^a-zA-Z]/, '') + ' ' + currentValue[3].replace(/[^0-9]/, '') + currentValue[4].replace(/[^a-zA-Z]/, '');
    }
    if (cvLength < 7) {
      // if(regex.test(currentValue.slice(1)))
      return currentValue[0].replace(/[^a-zA-Z]/, '') + currentValue[1].replace(/[^0-9]/, '') + currentValue[2].replace(/[^a-zA-Z]/, '') + ' ' + currentValue[3].replace(/[^0-9]/, '') + currentValue[4].replace(/[^a-zA-Z]/, '') + currentValue[5].replace(/[^0-9]/, '');
    }
    if (cvLength > 7) {
      return currentValue.slice(0, 7);
    }
    // return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

class EmployerRegister extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      employerName: null,
      admin_first: null,
      admin_last: null,
      admin_email: null,
      admin_phone: null,
      MAILINGLIST_IND: false,
      auth_same: true,
      emp_address: null,
      emp_address2: null,
      emp_city: null,
      emp_province: null,
      emp_postal: null,
      emp_loc: null,
      password: null,
      password_verify: null,
      test_resp: 'null',
      auth_first: null,
      auth_last: null,
      auth_phone: '',
      auth_email: null,
      confirm_code: null,
      isConfirm: false,
      accountid: null,
      emp_phone: null,
      passwordValid: true,
      passwordVerifyValid: true,
      formValid: false,
      errors: [],
      hasErrors: false,
      validEmployer: true,
      validAddress: true,
      validAddress2: true,
      validCity: true,
      validPostal: true,
      validProvince: true,
      validAdminFirst: true,
      validAdminLast: true,
      validAdminEmail: true,
      displayErrorMessage: false,
      existingEmployer: false,
      enteredEmail: false,
      isLoading: false,
      emailExistsSignup: false,
      member_number: null,
      validPhone: true,
      passwordType: 'password',
      passwordregex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&=+\/\\,><\':;|_~`])\S{8,99}$/,
      openTOU: false,
      accepted: false,
      saving: false,
      errorConfirm: false
    };
    this.handlePhone = this.handlePhone.bind(this);
    this.handlePostalCode = this.handlePostalCode.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleMailInd = this.handleMailInd.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAdminPhone = this.handleAdminPhone.bind(this);
    this.validEmail = this.validEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setMemberNumber = this.setMemberNumber.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.myEmployerNameHandler = this.myEmployerNameHandler.bind(this);
    this.passwordVerifyValidate = this.passwordVerifyValidate.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.acceptTOU = this.acceptTOU.bind(this);
    this.declineTOU = this.declineTOU.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  acceptTOU = () => {
    this.setState({ openTOU: false, accepted: true });
    this.handleCommit();
  }

  closeErrorConfirm = () => {
    this.setState({errorConfirm: false});
  }

  handleTOU = () => {
    if (this.state.accepted) {
      this.acceptTOU();
    } else {
      this.setState({ openTOU: true });
    }
  }

  declineTOU = () => {
    this.setState({ openTOU: false });
  }

  myEmployerNameHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if (event.target.value.length > 75) {
      val = event.target.value.substring(0, 75);
    }
    this.setState({ [nam]: val });
  }

  myPostalCodeChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  handleChange = (event) => {
    this.setState({
      auth_same: !this.state.auth_same
    });
  }

  handleMailInd = (event) => {
    this.setState({
      MAILINGLIST_IND: !this.state.MAILINGLIST_IND
    });
  }
  setMemberNumber = (event) => {
    this.setState({
      member_number: event.target.value
    })
  }

  handlePhone({ target: { value } }) {
    this.setState(prevState => ({ auth_phone: normalizeInput(value, prevState.auth_phone) }));
  };

  handleAdminPhone({ target: { value } }) {
    // console.log(value)
    this.setState(prevState => ({ admin_phone: normalizeInput(value, prevState.admin_phone) }));
  };


  handlePostalCode({ target: { value } }) {
    this.setState(prevState => ({ emp_postal: normalizePostalCode(value, prevState.emp_postal) }));
  };

  myPasswordHandler = (e) => {
    this.setState({ password: e.target.value });
    if (this.state.passwordregex.test(e.target.value) === true) {
      this.setState({ passwordValid: true });
    } else {
      this.setState({ passwordValid: false });
    }
  };

  myPasswordVerifyHandler = (e) => {
    this.setState({ password_verify: e.target.value });
    if (e.target.value === this.state.password) {
      this.setState({ passwordVerifyValid: true });
    } else {
      this.setState({ passwordVerifyValid: false });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    try {
      await Auth.signIn(this.state.member_number, this.state.password);
      let token = await Auth.currentSession().then(res => res.getIdToken());
      // console.log(token);
      let postBody = JSON.stringify({
        "employername": this.state.employerName,
        "employeraddress": this.state.emp_address,
        "employeraddress2": this.state.emp_address2,
        "employercity": this.state.emp_city,
        "employerprovince": this.state.emp_province,
        "employerpostal": this.state.emp_postal.replace(/ /g, '').toUpperCase(),
        "employerlocdesc": this.state.emp_loc,
        "admingivennames": this.state.admin_first,
        "adminsurname": this.state.admin_last,
        "adminemail": this.state.admin_email,
        "adminphonecountry": 1,
        "adminphoneareacode": Number(this.state.admin_phone.slice(0, 3)),
        "adminphone": this.state.admin_phone.slice(4, 7) + this.state.admin_phone.slice(8, 12),
        "authgivennames": this.state.auth_same === true ? this.state.admin_first : this.state.auth_first,
        "authsurname": this.state.auth_same === true ? this.state.admin_last : this.state.auth_last,
        "authphone": this.state.auth_same === true ? this.state.admin_phone.slice(4, 7) + this.state.admin_phone.slice(8, 12) : this.state.auth_phone.slice(4, 7) + this.state.auth_phone.slice(8, 12),
        "authphonecountry": 1,
        "authphonearea": this.state.auth_same === true ? Number(this.state.admin_phone.slice(0, 3)) : Number(this.state.auth_phone.slice(0, 3)),
        "authemail": this.state.auth_same === true ? this.state.admin_email : this.state.auth_email,
        "MAILINGLIST_IND": this.state.MAILINGLIST_IND,
        "authsame": this.state.auth_same === true ? "Y" : null

      })
      // console.log(postBody)
      let response = await fetch(config.apiGateway.URL + '/ers/' + this.state.member_number + '/registeremployer', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody });
      let responseJSON = await response.json();
      window.sessionStorage.setItem('employerid', responseJSON.employerid);
      // .then(response => response.json());
      // .then(json => console.log(json));
      // .then(json => alert(JSON.stringify(json)));
      // console.log(response);
      // let user = await Auth.currentUserInfo();
      // let accountBody = JSON.stringify({
      //   "cognito_uuid": user.attributes.sub,
      //   "cognito_identity_uuid": user.id,
      //   "roleid": 4,
      // })
      // let accountResp = await fetch('https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod/ers/' + this.state.accountid + '/account', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: accountBody })
      // console.log(accountResp);
      history.push("/IncompleteRegistration");
      this.props.userHasAuthenticated(true);
    } catch (error) {
      // console.log('Error confirming sign up. Please contact the system administrator', error)
    }
  }

  async resendConfirmationCode() {
    try {
        await Auth.resendSignUp(this.state.accountid);
        console.log('code resent successfully');
    } catch (err) {
        console.log('error resending code: ', err);
    }
  }

  handleConfirm = async () => {
    this.setState({saving: true});
    const { history } = this.props;
    try {
      await Auth.confirmSignUp(this.state.accountid, this.state.confirm_code);
      await Auth.signIn(this.state.accountid, this.state.password);

      let token = await Auth.currentSession().then(res => res.getIdToken());
      // console.log(token);
      let postBody = JSON.stringify({
        "employername": this.state.employerName,
        "employeraddress": this.state.emp_address,
        "employeraddress2": this.state.emp_address2,
        "employercity": this.state.emp_city,
        "employerprovince": this.state.emp_province,
        "employerpostal": this.state.emp_postal.replace(/ /g, '').toUpperCase(),
        "employerlocdesc": this.state.emp_loc,
        "admingivennames": this.state.admin_first,
        "adminsurname": this.state.admin_last,
        "adminemail": this.state.admin_email,
        "adminphonecountry": 1,
        "adminphoneareacode": Number(this.state.admin_phone.slice(0, 3)),
        "adminphone": this.state.admin_phone.slice(4, 7) + this.state.admin_phone.slice(8, 12),
        "authgivennames": this.state.auth_same === true ? this.state.admin_first : this.state.auth_first,
        "authsurname": this.state.auth_same === true ? this.state.admin_last : this.state.auth_last,
        "authphone": this.state.auth_same === true ? this.state.admin_phone.slice(4, 7) + this.state.admin_phone.slice(8, 12) : this.state.auth_phone.slice(4, 7) + this.state.auth_phone.slice(8, 12),
        "authphonecountry": 1,
        "authphonearea": this.state.auth_same === true ? Number(this.state.admin_phone.slice(0, 3)) : Number(this.state.auth_phone.slice(0, 3)),
        "authemail": this.state.auth_same === true ? this.state.admin_email : this.state.auth_email,
        "MAILINGLIST_IND": this.state.MAILINGLIST_IND,
        "authsame": this.state.auth_same === true ? "Y" : null

      })
      // console.log(postBody)
      let response = await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/registeremployer', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody })
      let responseJSON = await response.json();
      window.sessionStorage.setItem('employerid', responseJSON.employerid);
  
      let user = await Auth.currentUserInfo();
      let accountBody = JSON.stringify({
        "cognito_uuid": user.attributes.sub,
        "cognito_identity_uuid": user.id,
        "roleid": 4,
        "tou": "Y"
      })
      let accountResp = await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/account', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: accountBody })
      // console.log(accountResp);
      //history.push("/IncompleteRegistration");
      this.setState({isConfirm: false}, () => {
        window.location.href = '/IncompleteRegistration';
    })
    // window.location.href = '/IncompleteRegistration';
    } catch (error) {
      this.setState({errorConfirm: true});
      // console.log('Error confirming sign up. Please contact the system administrator', error)
    }
    this.setState({saving: false});
  }

  validEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === null || email === '' || !re.test(email)) {
      return false;
    }
    return true;
  }

  passwordVerifyValidate() {
    if (this.state.password === this.state.password_verify) {
      return true;
    } else {
      this.setState({ passwordVerifyValid: false });
      return false;
    }
  }

  validateForm = () => {
    
    if(!validatePostalCodeAgainstProvince(this.state.emp_postal, this.state.emp_province)){
      
      this.setState({  
        hasErrors: true, 
        displayErrorMessage: true, 
        validProvince: false
      });
     
      return false;
    }

    if (this.state.passwordValid & this.state.password !== null & this.state.password !== '') {
      if (this.state.passwordVerifyValid & this.passwordVerifyValidate() & this.state.password_verify !== null & this.state.password_verify !== '') {
        if (this.state.employerName !== null && this.state.employerName !== '') {
          if (this.state.emp_address !== null && this.state.emp_address !== '') {
            if (this.state.emp_city !== null && this.state.emp_city !== '') {
              if (this.state.emp_postal !== null && this.state.emp_postal !== '' && PostalCodeCheck(this.state.emp_postal, "CA") && this.state.emp_postal.length >= 6) {
                if (this.state.admin_first !== null && this.state.admin_first !== '') {
                  if (this.state.admin_last !== null && this.state.admin_last !== '') {
                    if (this.state.admin_email !== null && this.state.admin_email !== '' && this.validEmail(this.state.admin_email)) {
                      if (this.state.admin_phone !== null && this.state.admin_phone !== '' && this.state.admin_phone.replace(/\D+/g, "").length === 10) {
                        return true;
                      } else return false;
                    } else return false;
                  } else return false;
                } else return false;
              } else return false;
            } else return false;
          } else return false;
        } else return false;
      } else return false;
    } else return false;
  }

  checkErrors() {
    
    let newerrors = [];
    // this.setState({ errors: newerrors },() => { console.log(this.state.errors)});
    if (!this.state.passwordValid || this.state.password === null || this.state.password === '') {
      newerrors.push('Invalid Password');
    }
    if (!this.state.passwordVerifyValid || this.state.password_verify === null || this.state.password_verify === '') {
      newerrors.push('Password verification does not match');
    }
    if (this.state.employerName === null || this.state.employerName === '') {
      newerrors.push('Employer Name cannot be blank');
      this.setState({ validEmployer: false });
    } else { this.setState({ validEmployer: true }); }
    if (this.state.emp_address === null || this.state.emp_address === '') {
      newerrors.push('Street Address cannot be blank');
      this.setState({ validAddress: false });
    } else { this.setState({ validAddress: true }); }
    if (this.state.emp_city === null || this.state.emp_city === '') {
      newerrors.push('City cannot be blank');
      this.setState({ validCity: false });
    } else { this.setState({ validCity: true }); }
    if (this.state.admin_phone === null || this.state.admin_phone === '') {
      newerrors.push('Phone cannot be blank');
      this.setState({ validPhone: false });
    } else { this.setState({ validCity: true }); }
    if (this.state.emp_postal === null || this.state.emp_postal === '' || !PostalCodeCheck(this.state.emp_postal, "CA")) {
      newerrors.push('Postal Code cannot be blank');
      this.setState({ validPostal: false });
    } else { this.setState({ validPostal: true }); }
    if (this.state.admin_first === null || this.state.admin_first === '') {
      newerrors.push('Administrator First name cannot be blank');
      this.setState({ validAdminFirst: false });
    } else { this.setState({ validAdminFirst: true }); }
    if (this.state.admin_last === null || this.state.admin_last === '') {
      newerrors.push('Administrator Last Name cannot be blank');
      this.setState({ validAdminLast: false });
    } else { this.setState({ validAdminLast: true }); }
    if (this.state.admin_email === null || this.state.admin_email === '' || !this.validEmail(this.state.admin_email)) {
      newerrors.push('Administrator Email cannot be blank');
      this.setState({ validAdminEmail: false });
      console.log(this.state.validAdminEmail)
    } else { this.setState({ validAdminEmail: true }); }
    if (newerrors !== null & newerrors !== '') {
      this.setState({ errors: newerrors, hasErrors: true, displayErrorMessage: true })
   
    }
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      // console.log('value', e.target.value);
      this.handleConfirm();
    }
  }

  handleCommit = async () => {
    // console.log(PostalCodeCheck(this.state.emp_postal,"CA"));
    // console.log(this.validEmail(this.state.admin_email));
    // console.log(this.validateForm());
    if (this.validateForm()) {
      try {
        this.setState({ saving: true });
        let postBody = JSON.stringify({
          "employername": this.state.employerName
        })
        let accountResp = await fetch(config.apiGateway.URL + '/createAccount', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
          .then(response => response.json());
        // .then(json => console.log(json));
        if (accountResp.accountid !== -2) {
          // .then(json => this.setState({ accountid: json.accountid.toString() }));
          // console.log(this.state.accountid)
          this.setState({ accountid: accountResp.accountid.toString() });
          await Auth.signUp({
            username: accountResp.accountid.toString(),
            password: this.state.password,
            attributes: {
              given_name: this.state.admin_first,
              family_name: this.state.admin_last,
              email: this.state.admin_email,
            }
          });
          // await Auth.deleteUser();
          this.setState({
            isConfirm: !this.state.isConfirm,
            saving: false
          });
        } else {
          this.setState({ existingEmployer: true, saving: false });
        }
      } catch (e) {
        // console.log(e.code)
        if (e.code === 'InvalidLambdaResponseException') {
          //onError('Email already in use');
          this.setState({ emailExistsSignup: true });
        } else {
          onError(e);
        }
        this.setState({
          isConfirm: false,
          saving: false
        });
      }
    } else {
      // console.log("errors");
      this.checkErrors();
    }
  }

  handleClose = (event) => {
    this.setState({ displayErrorMessage: false });
  };

  closeExistingEmployer = (event) => {
    this.setState({ existingEmployer: false });
  };

  showPassword() {
    this.setState({ passwordType: 'text' })
  }

  hidePassword() {
    this.setState({ passwordType: 'password' })
  }

  componentDidUpdate = () => {
    if (this.state.isConfirm) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }


  render() {

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
      //<!-- HEADER NAVIGATION -->	
      <div class="title">
        <Prompt
            when={this.state.isConfirm}
            message='If you leave this page, you will have to start the registration process from the beginning.'
          />
        <Modal
          open={this.state.openTOU}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {/* style={{ position: 'absolute', top: '10%', left: '25%', width: '600px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }} */}
          <div >
            {/* <h3>Employer Portal Terms of Use</h3>
              <button style={{ margin: '10px' }} name="back" onClick={this.decline} class="button-green">Decline</button>
              <button name="back" onClick={this.accept} class="button-green">Accept</button> */}
            <TermsAndConditions acceptTOU={this.acceptTOU} declineTOU={this.declineTOU} />
          </div>
        </Modal>
        <Modal
            open={this.state.errorConfirm}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                The verification code you have entered is incorrect. Please re-enter the 6 digit verification code.
              </Typography>
              <button style={{ marginTop: '20px' }} name="back" onClick={this.closeErrorConfirm} class="button-green">Ok</button>
              {/* </div> */}
            </Box>
          </Modal>
        <Modal
            open={this.state.saving}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
              <CircularProgress size={100} />
            </div>
          </Modal>
        <Snackbar open={this.state.displayErrorMessage} onClose={this.handleClose}>
          <SnackbarContent style={{
            backgroundColor: '#f44336',
          }}
            message={<span id="client-snackbar">There are errors on the page</span>}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
        <Snackbar open={this.state.existingEmployer} onClose={this.closeExistingEmployer}>
          <SnackbarContent style={{
            backgroundColor: '#f44336',
          }}
            message={<span id="client-snackbar">An Employer with that name already exists</span>}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.closeExistingEmployer}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
        {/* {this.state.saving && <div>
          <CircularProgress />
        </div>} */}
        {!this.state.saving && <div class="row">
          {!this.state.isConfirm && !this.state.emailExistsSignup && <div class="column col-12">
            <h2>Register New Employer</h2>
            {/* <h2>test resp {this.test_resp}</h2> */}
          </div>}
          {this.state.isConfirm && <div class="column col-12">
            <h2>Verify Email</h2>
            {/* <h2>test resp {this.test_resp}</h2> */}
          </div>}
          {/* {this.state.hasErrors && <div>
            {this.state.errors.map(txt => <p class="errors column col-12">{txt}</p>)}
          </div>} */}

          {!this.state.isConfirm && !this.state.emailExistsSignup && <div class="column col-6 my_spp_auth_form">

            <form method="POST" class="border-form spin-on-submit" autocomplete="off">
              <div class="input required">
                <div style={{ display: "flex" }}>
                  <label for="org_name" class="bold-label ">Employer Name:</label>
              
                </div>
                <input type="text" name="employerName" placeholder="Enter Company Name" style={{ border: this.state.validEmployer ? '' : '1px solid #ff0000' }} class="wide-input" value={this.state.employerName} onChange={this.myEmployerNameHandler} autoComplete="off" />
              </div>
              <div class="input required">

                <label for="emp_address" class="bold-label ">Street Address:</label>

                <input type="text" name="emp_address" style={{ border: this.state.validAddress ? '' : '1px solid #ff0000' }} placeholder="Enter Company Mailing Address" class="wide-input" value={this.state.emp_address} onChange={this.myChangeHandler} autoComplete="off" />
              </div>
              <div class="input">

                <label for="emp_address2" class="bold-label ">Street Address 2:</label>

                <input type="text" name="emp_address2" style={{ border: this.state.validAddress2 ? '' : '1px solid #ff0000' }} placeholder="Enter Company Mailing Address" class="wide-input" value={this.state.emp_address2} onChange={this.myChangeHandler} autoComplete="off" />
              </div>
              <div class="input required">
                <label for="emp_city" class="bold-label ">City:</label>
                <input type="text" name="emp_city" style={{ border: this.state.validCity ? '' : '1px solid #ff0000' }} placeholder="Enter Company City" class="wide-input" value={this.state.emp_city} onChange={this.myChangeHandler} autoComplete="off" />
              </div>
              <div class="input required">
                  <ProvinceSelect
                    setProvince={(val)=>{this.setState({emp_province:val})}}
                    province={this.state.emp_province}
                    disabled={false}
                    error={!this.state.validProvince}
                  />
                </div>
              <div class="input required">
                <label for="emp_postal" class="bold-label ">Postal Code:</label>
                <input type="text" name="emp_postal" style={{ border: this.state.validPostal ? '' : '1px solid #ff0000' }} placeholder="S7K 2B9" class="wide-input" value={this.state.emp_postal} onChange={this.handlePostalCode} autoComplete="off" />
              </div>
              <div class="input">
                <label for="emp_loc" class="bold-label ">Location Description:</label>
                <input type="text" name="emp_loc" placeholder="Head Office, Downtown, Warehouse" class="wide-input" value={this.state.emp_loc} onChange={this.myChangeHandler} autoComplete="off" />
              </div>



              <div class="form-errors">
              </div>


            </form>
          </div>}


          {!this.state.isConfirm && !this.state.emailExistsSignup && <div class="column col-6 my_spp_auth_form">
            <form method="POST" class="border-form spin-on-submit" autocomplete="off">
              <div class="input required">
                <label for="admin_first" class="bold-label ">Administrator First Name:</label>
                <input type="text" name="admin_first" style={{ border: this.state.validAdminFirst ? '' : '1px solid #ff0000' }} class="wide-input" value={this.state.admin_first} onChange={this.myChangeHandler} autoComplete="off" />
              </div>
              <div class="input required">
                <label for="admin_last" class="bold-label ">Administrator Last Name:</label>
                <input type="text" name="admin_last" style={{ border: this.state.validAdminLast ? '' : '1px solid #ff0000' }} class="wide-input" value={this.state.admin_last} onChange={this.myChangeHandler} autoComplete="off" />
              </div>
              <div class="input required">
                <label for="admin_email" class="bold-label ">Administrator Email:</label>
                <input type="text" name="admin_email" style={{ border: (this.state.validAdminEmail || this.validEmail(this.state.validAdminEmail)) ? '' : '1px solid #ff0000' }} placeholder="admin@yourdomain.com" class="wide-input" value={this.state.admin_email} onChange={this.myChangeHandler} autoComplete="email" />
                <div style={{ fontsize: "0.8em" }}>The verification email, and account number will be sent to this email</div>
              </div>
              <div class="input required">
                <label for="admin_phone" class="bold-label ">Administrator Phone Number:</label>
                <input type="text" name="admin_phone" class="wide-input" value={this.state.admin_phone} onChange={this.handleAdminPhone} autoComplete="off" />
              </div>
              <div class="input required">
                <label for="password" style={{ display: "contents" }} class="bold-label ">Password:</label>{this.state.passwordType === 'password' && <IconButton aria-label="sort" title="Show Password" size="medium" onClick={() => this.showPassword()}>
                  <VisibilityIcon color="black" fontSize="inherit" />
                </IconButton>}
                {this.state.passwordType === 'text' && <IconButton aria-label="sort" title="Hide Password" size="medium" onClick={() => this.hidePassword()}>
                  <VisibilityOffIcon color="black" fontSize="inherit" />
                </IconButton>}
                <input type={this.state.passwordType} name="password" style={{ border: this.state.passwordValid ? '' : '1px solid #ff0000' }} class="wide-input" minLength="8" pattern={this.state.passwordregex} autoComplete="new-password" onChange={this.myPasswordHandler} required />
                <div style={{ fontsize: "0.8em" }}>At least 8 characters and must include lowercase, uppercase, numbers, and a special character (^ $ * . [ ] {"{}"} ( ) ? - " ! @ # % & / \ , {"<>"} ' : ; | _ ~ ` = +)</div>
              </div>
              <div class="input required">
                <label for="password_verify" class="bold-label ">Password (verify):</label>
                <input type={this.state.passwordType} name="password_verify" style={{ border: this.state.passwordVerifyValid ? '' : '1px solid #ff0000' }} class="wide-input" onChange={this.myPasswordVerifyHandler} autoComplete="new-password" required />
              </div>
              <div class="input">
                <label for="MAILINGLIST_IND" class="bold-label column">Opt into receiving marketing materials:</label>
                <input type="checkbox" style={{ top: '15px' }} class="largeCheckbox" name="MAILINGLIST_IND" checked={this.state.MAILINGLIST_IND} value={this.state.MAILINGLIST_IND} onChange={this.handleMailInd} />
                <div style={{ display: "inline-block", fontsize: "0.8em" }}>By checking the box and providing your email address, you are granting SPP permission to email you. You can revoke this permission any time using the SafeUnsubscribe link, found at the bottom of every email. We take your privacy seriously and our Privacy Policy (https://www.saskpension.com/privacy-policy/)  is available on our website. Emails are serviced by Constant Contact.</div>
              </div>
              <div class="input">
                <label for="auth_same" class="bold-label column">Authority is same as Administrator:<Tooltip disableFocusListener disableTouchListener title="This is the signing authority. If you are able to sign for account changes and pre-authorized payments, click this box. If not, uncheck the box and fill out the authority information">
                  <Button style={{ minWidth: "20px" }}>?</Button>
                </Tooltip></label>
                <input type="checkbox" style={{ top: '42px' }} class="largeCheckbox" name="auth_same" checked={this.state.auth_same} value={this.state.auth_same} onChange={this.handleChange} />
              </div>
              <br />
              {!this.state.auth_same && <div class="input required">
                <label for="auth_first" style={{ display: "inline-block" }} class="bold-label ">Authority First Name:</label>
                <input type="text" name="auth_first" class="wide-input" value={this.state.auth_first} onChange={this.myChangeHandler} autoComplete="off" />
              </div>}
              {!this.state.auth_same && <div class="input required">
                <label for="auth_last" class="bold-label ">Authority Last Name:</label>
                <input type="text" name="auth_last" class="wide-input" value={this.state.auth_last} onChange={this.myChangeHandler} autoComplete="off" />
              </div>}
              {!this.state.auth_same && <div class="input required">
                <label for="auth_phone" class="bold-label ">Authority Phone Number:</label>
                <input type="text" readOnly={this.state.auth_same} name="auth_phone" class="wide-input" value={this.state.auth_phone} onChange={this.handlePhone} autoComplete="off" />
              </div>}
              {!this.state.auth_same && <div class="input required">
                <label for="auth_email" class="bold-label ">Authority Email:</label>
                <input type="text" id="inputID" name="auth_email" placeholder="admin@yourdomain.com" class="wide-input" value={this.state.auth_email} onChange={this.myChangeHandler} autoComplete="email" />
              </div>}

              <div class="form-errors">
              </div>
              <br />

            </form>
          </div>}
          {this.state.isConfirm && <div class="column col-6 my_spp_auth_form">
            <form method="POST" class="border-form spin-on-submit" onSubmit={this.handleConfirm} autocomplete="off">
              <label class="bold-label ">Check your email for the 6-digit verification code</label>
              <div class="input required">
                <label for="confirm_code" class="bold-label ">Verification Code:</label>
                <input type="text" name="confirm_code" class="wide-input" maxLength={6} onKeyDown={this.keyPress} value={this.state.confirm_code} onChange={this.myChangeHandler} autoComplete="new-password" />
              </div>
              <div class="loginbutton" style={{ textalign: 'right' }}>
                {this.state.isConfirm && <button type="button" name="register" class="button-green" onClick={this.handleConfirm}>Verify</button>}
              </div>
            </form>
          </div>}
          <div class="loginbutton" style={{ textalign: 'right' }}>
            {/* <Link to={`/IncompleteRegistration`}> */}
            {!this.state.isConfirm && !this.state.emailExistsSignup && <button type="button" name="register" class="button-green" onClick={this.handleTOU}>Create Account</button>}

            {/* </Link> */}
          </div>
          {this.state.emailExistsSignup && <div class="column col-6 my_spp_auth_form">
            <form onSubmit={this.handleSubmit} class="border-form spin-on-submit my_spp_auth_form" >
              {/* <!-- Note - intentionally visually different from the incorrect credentials message --> */}
              <label class="bold-label ">The email address you entered is already being used by an existing administrator.</label>
              <label class="bold-label ">If you are trying to set up another employer, enter your User ID and Password to Continue</label>

              <div class="input required">
                <label for="member_number" class="bold-label ">User ID:<Tooltip disableFocusListener disableTouchListener title="This Number can be found in your verification email">
                  <Button style={{ minWidth: "20px" }}>?</Button>
                </Tooltip>
                </label>
                <input type="text" name="member_number" class="wide-input" value={this.state.member_number} onChange={this.setMemberNumber} />
              </div>
              <div class="input required">
                <label for="password" class="bold-label ">Password:</label>
                <input type="password" security='disc' style={{ fontFamily: 'text-security-disc' }} name="password" class="wide-input" onChange={this.myPasswordHandler} />
              </div>

              <div class="form-errors">
              </div>


              <button style={{ marginTop: '20px' }} type="submit" value="Submit" class="button-green">Submit</button>
            </form>
          </div>}

        </div>}

      </div>
    )
  }
}

export default withRouter(EmployerRegister)