import React, { Component } from 'react';
import logo from '../images/logo-header.png';
import IconButton from '@mui/material/IconButton';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelp';
import { Modal } from '@mui/material';
import Help from './Help';
import MaintenanceNotice from '../Components/UnderMaintenance';
import { browserName, browserVersion } from "react-device-detect";
import browserConfig from "../browserConfig";



import { withRouter } from 'react-router-dom';



class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // employerName: null,
      auth_first: null,
      auth_last: null,
      auth_email: null,
      auth_same: true,
      pass: null,
      pass_verify: null,
      page: 'EmployerProfile',
      employers: [],
      currentEmployerId: null,
      employerName: '',
      emp_address: '',
      emp_city: '',
      emp_postal: '',
      emp_loc: '',
      homepage: '',
      openHelp: false,
      isSupported: true
    
    };
    this.openHelpDialog = this.openHelpDialog.bind(this);
 
   
  }
  

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  handleChange = (event) => {
    this.setState({
      page: event.target.value
    });
  }

 

 /* componentDidUpdate () {
    
  }
*/
  componentDidMount() {
    let bName = browserName.valueOf();
    let bVersion = browserVersion.valueOf();
    

  
    if (bName.toLowerCase() in browserConfig.supported) {
      //if(browserConfig.supported.hasOwnProperty(bName.toLowerCase())){
      console.log(browserConfig.supported[bName.toLowerCase()]);
      if (!(bVersion >= browserConfig.supported[bName.toLowerCase()])) {
        console.log('unsupported Version')
        this.setState({ isSupported: false })
      }
    }
    else {
      console.log('unsupported Version')
      this.setState({ isSupported: false })
    }
    this.setState({ homepage: window.location.origin })
  }

  async getNotice() {

  }

  openHelpDialog = () => {
    this.setState({ openHelp: true });
  }

  closeHelpDialog = () => {
    this.setState({ openHelp: false });
  }

  render() {
    return (
      //<!-- HEADER NAVIGATION -->	
      <div class="title">
       
         
        <div>
          <Modal
            open={this.state.openHelp}
            aria-labelledby="Help"
            aria-describedby="Help menu"
          >
            <div style={{ position: 'absolute', top: '5%', left: window.innerWidth / 2 - 375, width: '750px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
              <Help location={window.location.pathname} />
              <button style={{ margin: '10px' }} name="back" onClick={this.closeHelpDialog} class="button-green">Close</button>
            </div>
          </Modal>
        </div>
        <div style={{ background: window.location.host === 'mybusiness.saskpension.com' ? "#53565a" : "blue", padding: "8px 0" }} >
          <div class="row">
            <div class="column col-12" style={{ padding: "0px" }}>
              <div class="column col-4 text-center">
                <div class="header-logo">
                  <a class='logo' href={this.state.homepage}><img src={logo} alt="SPP logo" style={{ filter: "invert(1)", maxWidth: "25%", marginBottom: "0px" }}></img>
                  </a>
                </div>

              </div>
              <div class="column col-4 text-center" style={{ marginTop: "9px" }}>
                <h3 style={{ color: "white", padding: "0px" }}>
                  {
                    window.location.host === 'mybusiness.saskpension.com' ? 'MyBusiness Employer Portal' : 
                      window.location.host === 'test.mybusiness.saskpension.com' ? 'TEST MyBusiness Employer Portal' :'DEV MyBusiness Employer Portal' 
                  }
                </h3>
              </div>
              <div class="column col-4 text-right nav-phone" style={{ marginTop: "10px" }}>

                <strong><a href="tel:18006677153" onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></strong>&nbsp;&nbsp;
                <IconButton aria-label="help" title="Help" size="medium" onClick={() => this.openHelpDialog()}>
                  <LiveHelpOutlinedIcon fontSize="inherit" style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div class="main-nav" style={{ maxHeight: "64px" }} >



          {/* <div class="row">
        <div class="column col-4 text-center">
            <div class="header-logo" style={{marginTop:"-20px"}}>
                <a class='logo' href="#"><img src={logo} alt="SPP logo" style={{ filter:"invert(1)", maxWidth:"25%", marginBottom:"0px"}}></img>
                </a>
            </div>
          
        </div>
        
        
        
        <div class="column col-8 text-center" style={{marginTop:"-17px"}}>
            <div class="hero-buttons">
                    <div class="green-current" >
                        <a href="#" onclick="window.close();return false;" class="button"><span>Return to main website</span></a>
                    </div>
                </div>
                        
        </div> 
</div> */}
        </div>
        {!this.state.isSupported && <div>
          <p style={{ textAlign: '-moz-center', textAlign: '-webkit-center' }}>Outdated or Unsupported browser detected, for the best perfomance of this site, please use Chrome, Firefox, or Edge browsers updated to the latest version.</p>

        </div>}
        {this.props.showMessage && <MaintenanceNotice message={this.props.message} />
        }
      </div>
    )
  }
}

export default withRouter(Header)
