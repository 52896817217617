import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import config from "./aws-exports";
import Amplify from '@aws-amplify/core';
import config from './config';
import TagManager from 'react-gtm-module'
// Amplify.configure(config);

//spp-employer-a user pool
// Amplify.configure({
//   Auth: {
//     region: "ca-central-1",
//     userPoolId: "ca-central-1_LYoXp05tJ",
//     userPoolWebClientId: "549acdato81ppoks75vacn5hcj",
//   },
//   API: {
//     endpoints: [
//       {
//         name: "ERS",
//         endpoint:
//           "https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod",
//       },
//     ],
//   },
// });

//spp-employer-b user pool
Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "ERS",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ],
  },
  Storage: { 
    bucket: config.storage.BUCKET,
    region: config.storage.REGION,

   },
});

const tagManagerArgs = {
  gtmId: 'GTM-TQ5KK4F'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
  <App />
</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
