const browserConfig = {
    supported: {
        chrome: 79,
        firefox: 90,
        edge: 92
        // android: '4.4.3',
        // ie: '11',
        // ios_saf: '12.2',
        // opera: '85',
        // safari: '13.1',
        // samsung: '16'
    }
};

export default browserConfig;