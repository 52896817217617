import { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


class TermsAndConditions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accepted: false
        }
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 850,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            height: 600
        };
        // var link = <a href={this.makeHref('login')}>log in</a>;
        var link = <a href="mailto:info@saskpension.com">info@saskpension.com</a>
        var link2 = <a href="http://www.qp.gov.sk.ca/">http://www.qp.gov.sk.ca/</a>
        var link3 = <a href="https://www.saskpension.com/privacy-policy/">https://www.saskpension.com/privacy-policy/</a>
        return (
            <div>
                <Box sx={style}>
                    <Typography id="modal-modal-title" align="center" variant="h6" component="h2">
                        Employer Portal Terms of Use
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please read the following statement carefully, and at the bottom, click one of the buttons to indicate whether you agree or do not agree to the terms for using this site. If you do not agree with the terms, you will not be able to enter the site.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        This site is provided by The Saskatchewan Pension Plan ("SPP") on behalf of The Saskatchewan Pension Plan Board of Trustees ("Board") as administrator of The Saskatchewan Pension Plan ("Plan"). These Terms of Use describe the risks that may be involved in using this service, and limit the liability of SPP and the Board in the event that you suffer any loss as a result of using the site or the services within it. By choosing I agree below, you are acknowledging that you have read these Terms of Use and agree to be bound by them. Access to the site and to the services within it are provided to you in consideration of your agreeing to these Terms of Use.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        If you have concerns with these Terms of Use, you may contact {link}, (306) 463-5410 or 1-800-667-7153. You may obtain any of the information that the site contains by directly contacting SPP. Any information obtained from the site should be verified before any decision based on it is made.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Definition of “Personal Data”
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    In the following document we use the term personal information and personal data, these terms are used interchangeably and are defined as:
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Business information:
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Business Name
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Contact Name
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Address
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Email address
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Bank account information
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Credit card information 
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Employee information:
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Employee Name 
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Address 
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Phone number
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Email address
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    •	&nbsp;&nbsp;Social insurance number (SIN)
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The above information will be stored with in the Amazon Web Services platform, localized to the Canada S3 data center, and on SPP’s servers. This information could be displayed on screen as part of normal use of Employer Portal.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    By choosing I agree, you agree to accept this definition of personal data.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Purpose and limitations of the site
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    This site provides you with an opportunity to view details regarding Business Plan, including your employee and remittance information. SPP and the Board make no promises that the information the site provides will be complete, appropriate to your circumstances, error-free or accessible at all times.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    By choosing I agree, you agree to accept the site "as is".
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Official documents determine any benefits
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The Plan is governed by The Saskatchewan Pension Plan Act and Regulations. Any benefits that may be payable to you from the Plan will be determined by this legislation and other applicable laws. If information on this site conflicts with those legal requirements, the legislation will prevail. The legislation may change at any time and this site may not always be current. Copies of Plan Legislation are available from the Queen’s printer website at {link2}.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Your use of the site does not grant any rights
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Your use of the site does not give you any contractual or other rights to receive any benefit or entitlements.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Compliance with privacy law
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    SPP’s systems are designed to protect information from unauthorized access, and SPP and the Board comply with applicable data privacy rules and regulations in Saskatchewan. At the same time, you also have an obligation to safeguard your personal information from use by others.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    No one can guarantee the safety of sending data over the Internet
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The personal data is sent over the Internet in an encrypted format, using secure Web servers, with the intent of protecting the data from access by others. However, SPP and the Board make no promises that this protection is invincible. It may be possible for someone to decipher the encryption, or a server may fail to properly encrypt the data.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Therefore, by choosing I agree, you agree to accept the risk that someone may be able to access your personal information. SPP and the Board will not be liable for any harm that you may experience if an unauthorized person breaks the encryption or if a server fails.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Security Features
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    SPP is obligated to protect member’s personal information by making reasonable security arrangements against such risks as loss, misuse, unauthorized access, alteration, disclosure or disposal/destruction. Security measures have been integrated into the day-to-day operating practices of SPP. This includes the employment of technology to process transactions at this website. We use several layers of robust security methods including Transport Layer Security (TLS) technology, encryption, HTTP Strict Transport Security, firewalls and timed log-outs among others to ensure the confidentiality of your personal and financial information.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Regardless of the protective measures taken to ensure a maximum of confidentiality of personal information, there is always an inherent risk, beyond our control, associated with both the technologies and the telecommunication media used. The possibility of a breach in confidentiality in exchanges over the Internet is a risk all users must assume and by choosing I agree you accept this risk in accessing and using the site.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    Limitation of Liability
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    SPP and the Board, as well as any employee, officer or agent of any of them, either jointly or severally, will not be liable to you or a third party for any loss, damage, or expense that may result from any decision or action that you take in reliance upon the information the site contains or the results obtained by you from the site. This includes direct, indirect, special, incidental, or consequential damages, losses, or expenses. This limitation applies even if SPP and the Board, or an employee, officer or agent of any of them, has been negligent in the creation, operation or application of the site or the services provided within it. By choosing I agree, you accept this limitation of liability and agree, on your behalf and on behalf of any person claiming through or on behalf of you, to make no claim against any party to which this limitation applies arising in any way whatsoever out of your use of the site or the services provided within it.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    WHAT HAPPENS WHEN YOU MAKE AN ONLINE CONTRIBUTION?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Your transaction will be completed once credit card authorization has been received. Payments will be charged to your credit card by Global Payments, our credit card processor, typically within seven business days.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    When making a credit card contribution via Employer Portal you will be asked for your Card Verification Value (CVV) or Card Verification Code (CVC) as confirmation to complete the secure transaction.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    You will receive a confirmation email from our website confirming the contribution. The payment will be posted to your account at SPP within two business days.
                    </Typography>
                    <Typography id="modal-modal-description" style={{ fontWeight: 600 }} variant="subtitle1" sx={{ mt: 2 }}>
                    More Privacy information
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    You can read our full website privacy and terms here ({link3}). 
                    </Typography>
                    <Button class="button-green" style={{marginTop: '20px', marginLeft: '235px'}} onClick={() => this.props.declineTOU()}>I Do Not Agree</Button>
                    <Button class="button-green" style={{marginTop: '20px', marginLeft: '20px'}} onClick={() => this.props.acceptTOU()}>I Agree</Button>
                </Box>
            </div>
        );

    }



}

export default TermsAndConditions;