import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Badge from '@mui/material/Badge';
import { Auth } from 'aws-amplify'
import ChangeEmployer from './ChangeEmployer';
import Button from '@mui/material/Button';
import Logout from './Logout';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { withRouter } from 'react-router-dom'
import { onError } from "../libs/errorLib";
import config from '../config';
import IdleTimer from 'react-idle-timer';
import {Paper} from '@mui/material';
import { Modal } from '@mui/material';



class HeaderMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employerName: null,
      auth_first: null,
      auth_last: null,
      auth_email: null,
      auth_same: true,
      pass: null,
      pass_verify: null,
      page: 'EmployerProfile',
      anchorEl: null,
      setAnchorEl: null,
      currentUser: null,
      jsonresponse: null,
      unread: 0,
      toEmployer: "0",
      searchText: '',
      employerStatus: '',
      isIdle: false,
      time: { m: 2, s: 0 }, seconds: 120
    };
    this.mainTimer = 0;

    // this.reLoad = this.reLoad.bind(this);
    this.setToEmployer = this.setToEmployer.bind(this);
    this.updateEmployerId = this.updateEmployerId.bind(this);
    this.updateHeader = this.updateHeader.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
  }

  static timerRoutes = ['/ManageEmployees',
                        '/AddEmployer',
                        'AdministratorProfile',
                        '/ContributionProfile',
                        '/Documents',
                        '/EmployerProfile',
                        '/ManageEmployees',
                        '/Notes',
                        '/Remit',
                        '/SecureMessaging'
                      
                       ]; 

  setToEmployer(employer) {
    this.setState({ toEmployer: employer });
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  handleChange = (event) => {
    this.setState({
      page: event.target.value
    });
  }

  handleOnAction = (event) => {
    if (!this.state.isIdle) {
      this.setState({ lastAction: new Date().getTime() })
    }
  }

  logout= () => {
    this.setState({ changes: false }, async () => {
      let host = window.location.origin;
      await Auth.signOut();
      window.sessionStorage.clear();

      window.location.href = host + "/Login";
    })
  }

  startCountdown() {
    this.setState({ countDown: 120 })
  }

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  counter = () => {
   
  
    
    if ((new Date().getTime() - this.state.lastAction) >= (config.timer.prompt * 60 * 1000) + (config.timer.idleTimout * 60 * 1000)) {
      this.logout();
    }
    if ((new Date().getTime() - this.state.lastAction) >= config.timer.idleTimout * 60 * 1000) {
   
      this.countDown();
      this.setState({ isIdle: true })
    }
  }

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = ((config.timer.prompt + config.timer.idleTimout) * 60) - Math.floor((new Date().getTime() - this.state.lastAction) / 1000);
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      this.logout();
    }
  }

  stopTimer = () => {
    this.idleTimer.reset();
    this.setState({ isIdle: false, seconds: 120, time: { m: 2, s: 0 }, count: 0, lastAction: new Date().getTime() })
  }


  handleNavigate = async(value) => {
 
      let finished = false;
      let noticeS3 = null;
      for (let i = 0; i < 10 && !finished; i++) {
        noticeS3 = await fetch(config.apiGateway.URL + '/getNoticeMessage', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
          .then((response) => {
            if (response.ok) {
              finished = true;
              return response.json();
            }
          }).catch((error) => {
            // console.log(error)
          });
      }
      if (noticeS3 !== undefined) {
        // console.log(noticeS3);
        let curdate = new Date();
        let startdate = new Date(noticeS3.noticestart);
        let enddate = new Date(noticeS3.noticeend);
        // console.log(curdate);
        // console.log(startdate);
        // console.log(enddate);
        // console.log(curdate.getTime() > startdate.getTime() && curdate.getTime() < enddate.getTime());
        let isShowMessage = curdate.getTime() > startdate.getTime() && curdate.getTime() < enddate.getTime();
        if (isShowMessage) {
          //refresh
          window.location.reload(false);
        }
      }
      this.setState({ page: value })
    }

    handleClick = (event) => {
      this.setState({ setAnchorEl: event.target });
    };

    handleClose = () => {
      this.setState({ setAnchorEl: (null) });
    };

    // componentDidUpdate(prevProps, prevState) {
    //   if (prevProps.employerid.employerid !== this.props.employerid.employerid) {
    //     this.updateHeader();
    //   }
    // }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.unreadSM.unreadSM !== this.props.unreadSM.unreadSM) {
        this.setState({ unread: this.props.unreadSM.unreadSM })
        // console.log(prevProps.unreadSM.unreadSM + "next unread" + this.props.unreadSM.unreadSM)
      }
    }



  async updateHeader() {
      // if(this.props.employerid.employerid !== prevProps.employerid.employerid) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      // {
      // console.log("updatingHeader");
      this.props.header.setIsLoaded(false);
      let token = await Auth.currentSession().then(res => res.getIdToken());
      this.setState({
        currentUser: token.payload.given_name + ' ' + token.payload.family_name,
      })
      // console.log(token);
      let user = await Auth.currentUserInfo();
      // let me = await Auth.currentSession().then(res => res.getAccessToken());
      // console.log(token);

      if (user !== null) {
        this.setState({
          accountid: user.username
        });

        this.setState({ currentEmployerId: this.props.employerid.employerid })

        if (this.props.employerid.employerid != null) {

          await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/' + this.props.employerid.employerid + '/employer', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
            .then(response => response.json())
            // .then(json => console.log(json));
            .then(json => {
              // console.log(json)
              this.setState({
                employerName: json.items[0].surname,
                emp_address: json.items[0].address1,
                emp_city: json.items[0].city,
                emp_postal: json.items[0].postalcode,
                emp_loc: json.items[0].locationdescription,
                employerStatus: json.items[0].employer_status
              })
            }).catch((error) => {
              // console.log(error)
            });

          this.props.header.setIsLoaded(true);

          let messageResponse = await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/' + this.props.employerid.employerid + '/securemessages/unread', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
            .then(response => response.json());
          if (messageResponse.items !== undefined)
            if (messageResponse.items.length > 0) {
              // console.log(messageResponse.items[0]["count(1)"]);
              this.setState({ unread: messageResponse.items[0].unread })
              this.props.unreadSM.setunreadSM(Number(messageResponse.items[0].unread));
            }
        }
        // console.log(this.state.employerName);
      }
      //this if statment must remain == '1' 
      if (this.props.employerid.employerid == 1) {
        // window.location.href = '/AdministratorProfile';
        this.props.asAdmin.setAsAdmin(true);
        this.props.history.push('/AdministratorProfile');
      } else {
        this.props.asAdmin.setAsAdmin(false);
        this.props.history.push('/EmployerProfile');
        // browserHistory.push('/EmployerProfile')
      }

      // }
    }

  async componentDidMount() {
  
      const { location } = this.props;
      console.log(location.pathname);
      if (!this.props.isAdmin && HeaderMenu.timerRoutes.includes( location.pathname)) {
        this.mainTimer = setInterval(this.counter, 1000);
        this.setState({ lastAction: new Date().getTime() })
      }
      if (this.props.employerid.employerid == 1 && window.location.pathname === '/EmployerProfile') {
        // this.props.history.push('/AdministratorProfile');
        // this.updateHeader();
        window.location.href = '/AdministratorProfile';
      } else {
        this.props.header.setIsLoaded(false);
        let token = await Auth.currentSession().then(res => res.getIdToken());
        this.setState({
          currentUser: token.payload.given_name + ' ' + token.payload.family_name,
        })
        // console.log(token);
        let user = await Auth.currentUserInfo();
        let me = await Auth.currentSession().then(res => res.getAccessToken());
        // console.log(token);

        if (user !== null) {
          this.setState({
            accountid: user.username
          });
          // console.log(this.props.asAdmin.asAdmin)
          let isError = false;
          let employerResp = await fetch(config.apiGateway.URL + '/ers/' + user.username + '/employers', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
            .then(response => response.json()).catch((error) => {
              isError = true;
              onError(error.message);
              //console.log(error)
            });

            if(!isError){
          // console.log(this.props.employerid.employerid);
          let curEmployerId = this.props.employerid.employerid;
          if (employerResp.items !== undefined && employerResp.items.length > 0 && (this.props.employerid.employerid === '' || this.props.employerid.employerid === null)) {
            curEmployerId = this.props.asAdmin.asAdmin ? 1 : employerResp.items[0].employerid
            // console.log(this.props.asAdmin.asAdmin);
            this.setState({
              employers: employerResp.items,
              // currentEmployerName: employerResp.items[0].surname,
              currentEmployerId: curEmployerId,
              toEmployer: "0"
            })
            this.props.employerid.setEmployerid(curEmployerId)
          } else if (employerResp.items !== undefined && employerResp.items.length > 0 && this.props.employerid.employerid !== '') {
            curEmployerId = this.props.employerid.employerid;
            // console.log(curEmployerId);
            this.setState({
              employers: employerResp.items,
              // currentEmployerName: employerResp.items[0].surname,
              currentEmployerId: curEmployerId,
              toEmployer: "0"
            })

          }
          // console.log(this.state.employers)
          // console.log(this.state.currentEmployerId)

          if (curEmployerId != null) {

            let json = await fetch(config.apiGateway.URL + '/ers/' + user.username + '/' + curEmployerId + '/employer', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
              .then(response => response.json());
            // .then(json => console.log(json));
            // .then(json => 
            if (json !== undefined && json.items !== undefined && json.items.length > 0) {
              this.setState({
                employerName: json.items[0].surname,
                emp_address: json.items[0].address1,
                emp_city: json.items[0].city,
                emp_postal: json.items[0].postalcode,
                emp_loc: json.items[0].locationdescription,
                employerStatus: json.items[0].employer_status
              })
            };
            // )




            let messageResponse = await fetch(config.apiGateway.URL + '/ers/' + user.username + '/' + curEmployerId + '/securemessages/unread', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
              .then(response => response.json());
            // console.log(messageResponse);
            if (messageResponse.items !== undefined && messageResponse.items[0] !== undefined && messageResponse.items[0].unread > 0) {
              // console.log(messageResponse.count);
              this.setState({ unread: messageResponse.items[0].unread })
              this.props.unreadSM.setunreadSM(messageResponse.items[0].unread);
            }
            this.props.header.setIsLoaded(true);
          }
          // console.log(this.state.employerName);
        }
        }
      }

    }

   
    handleClickAway() {
      this.setState({ searchText: '' });
      // console.log("clickaway");
    }

    setSearchText(value) {
      this.setState({ searchText: value });
      // console.log("clickaway");
    }

    updateEmployerId() {
      if (this.state.toEmployer !== null && this.props.employerid !== this.state.toEmployer) {
        this.props.employerid.setEmployerid(this.state.toEmployer);
        this.updateHeader();
        this.setState({ toEmployer: null, searchText: '' });
      }
    }

   

    render() {

      return (
        //<!-- HEADER NAVIGATION -->	
        this.props.header.setIsLoaded && 
        <div class="title">
           <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * config.timer.idleTimout}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            events={['keydown', 'mousedown', 'touchstart', 'MSPointerDown']}
            onAction={this.handleOnAction}
            stopOnIdle={true}
            debounce={250}
            startOnMount={true}
          />
           <Modal
              open={this.state.isIdle}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={{ position: 'absolute', top: '40%', left: '35%', width: '350px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
                <Paper elevation={1}>
                  <div style={{ padding: '10px' }}>
                    Your session will timeout in: {this.state.time.m}:{("0" + this.state.time.s).slice(-2)}
                    <button style={{ margin: '10px' }} name="back" onClick={this.stopTimer} class="button-green">Extend Session</button>
                    {/* <button name="back" onClick={this.logout} class="button-green">Logout</button> */}
                  </div>
                </Paper>
              </div>
            </Modal>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div class="main-nav" style={{ maxHeight: "64px", display: "grid" }} >
              <div class="row" style={{ paddingTop: "10px", paddingBottom: "10px", display: "inline-flex" }}>
                {!this.props.asAdmin.asAdmin && <div class="column col-12" style={{ display: "inline-flex" }}> <h3 style={{color: this.state.employerStatus === 'I' ? 'crimson' : 'black', overflow: "hidden"}} title={this.state.employerName}>Managing:{this.state.employerName !== null && this.state.employerName.length > 30 ? this.state.employerName.slice(0, 28) + '...' : this.state.employerName} </h3> <ChangeEmployer employerid={this.props.employerid} toEmployer={this.state.toEmployer} searchText={this.state.searchText} setSearchText={this.setSearchText} setEmployerid={this.setToEmployer} name={this.state.employerName} /><Button class="button-green" style={{ maxHeight: "42px", marginLeft: "10px" }} onClick={this.updateEmployerId}>Update</Button></div>}
                {this.props.asAdmin.asAdmin && <div class="column col-12" style={{ display: "inline-flex" }}> <h3>SPP Administrator </h3> <ChangeEmployer employerid={this.props.employerid} setEmployerid={this.setToEmployer} name={this.state.currentUser} searchText={this.state.searchText} setSearchText={this.setSearchText} /><Button class="button-green" style={{ maxHeight: "42px", marginLeft: "10px" }} onClick={this.updateEmployerId}>Update</Button></div>}
                <Logout />
              </div>
            </div>
          </ClickAwayListener>
          <div class="row">

            <div class="row">

              <div class="column col-12">

                {!this.props.asAdmin.asAdmin && <table class="member-tabs-table">
                  <tbody>
                    <tr>
                      <Link to={`/EmployerProfile`}>
                        <td value='EmployerProfile' class="button-green" style={{ backgroundColor: window.location.pathname !== '/EmployerProfile' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('EmployerProfile')}>Employer Profile</td>
                      </Link>
                      <Link to={`/ManageEmployees`}>
                        <td value='ManageEmployees' class="button-green" style={{ backgroundColor: window.location.pathname !== '/ManageEmployees' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('ManageEmployees')}>Manage Employees</td>
                      </Link>
                      <Link to={`/ContributionProfile`}>
                        <td value='ContributionProfile' class="button-green" style={{ backgroundColor: window.location.pathname !== '/ContributionProfile' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('ContributionProfile')}>Payment Method</td>
                      </Link>
                      <Link to={`/AdministratorProfile`}>
                        <td value='AdministratorProfile' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AdministratorProfile' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('AdministratorProfile')}>Administrator Profile</td>
                      </Link>
                      <Badge badgeContent={this.state.unread} color="primary" style={{ verticalAlign: "initial" }}>
                        <Link to={`/SecureMessaging`}>
                          <td value='SecureMessaging' class="button-green" style={{ backgroundColor: window.location.pathname !== '/SecureMessaging' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('SecureMessaging')}>Secure Messaging</td>
                        </Link>
                      </Badge>
                      <Link to={`/Remit`}>
                        <td value='Remit' class="button-green" style={{ backgroundColor: window.location.pathname !== '/Remit' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('Remit')}>Remit</td>
                      </Link>
                      <Link to={`/Documents`}>
                        <td value='Documents' class="button-green" style={{ backgroundColor: window.location.pathname !== '/Documents' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('Documents')}>Documents</td>
                      </Link>
                      { this.props.isAdmin && <Link to={`/Notes`}>
                        <td value='Notes' class="button-green" style={{ backgroundColor: window.location.pathname !== '/Notes' ? "#dddddd" : this.state.employerStatus === 'I' ? 'tomato' : "#9fcf67" }} onClick={() => this.handleNavigate('Notes')}>Notes</td>
                      </Link>}
                    </tr>
                  </tbody>
                </table>}
                {this.props.asAdmin.asAdmin && <table class="member-tabs-table">
                  <tbody>
                    <tr>
                      <Link to={`/AdministratorProfile`}>
                        <td value='AdministratorProfile' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AdministratorProfile' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('AdministratorProfile')}>Administrator Profile</td>
                      </Link>
                      <Link to={`/AdminDashboard`}>
                        <td value='AdminDashboard' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AdminDashboard' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('AdminDashboard')}>Dashboard</td>
                      </Link>
                      <Badge badgeContent={this.state.unread} color="primary" style={{ verticalAlign: "initial" }}>
                        <Link to={`/SecureMessaging`}>
                          <td value='SecureMessaging' class="button-green" style={{ backgroundColor: window.location.pathname !== '/SecureMessaging' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('SecureMessaging')}>Secure Messaging</td>
                        </Link>
                      </Badge>
                      <Link to={`/AdminRemitReport`}>
                        <td value='AdminRemitReport' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AdminRemitReport' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('AdminRemitReport')}>Remittances</td>
                      </Link>
                      <Link to={`/PayrollProvider`}>
                        <td value='PayrollProvider' class="button-green" style={{ backgroundColor: window.location.pathname !== '/PayrollProvider' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('PayrollProvider')}>Payroll Provider Upload</td>
                      </Link>
                      <Link to={`/AddEmployer`}>
                        <td value='AddEmployer' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AddEmployer' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('AddEmployer')}>Add Employer</td>
                      </Link>
                      <Link to={`/Documents`}>
                        <td value='Documents' class="button-green" style={{ backgroundColor: window.location.pathname !== '/Documents' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('Documents')}>Documents</td>
                      </Link>
                      <Link to={`/Maintenance`}>
                        <td value='Maintenance' class="button-green" style={{ backgroundColor: window.location.pathname !== '/Maintenance' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('Maintenance')}>Maintenance</td>
                      </Link>
                      <Link to={`/AdjustmentHoldingTank`}>
                        <td value='AdjustmentHoldingTank' class="button-green" style={{ backgroundColor: window.location.pathname !== '/AdjustmentHoldingTank' ? "#dddddd" : "#9fcf67" }} onClick={() => this.handleNavigate('AdjustmentHoldingTank')}>Adjustment Tank</td>
                      </Link>
                    </tr>
                  </tbody>
                </table>}
              </div>


            </div>

          </div>
        </div>
      )
    }
  }

export default withRouter(HeaderMenu);