import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ConfirmSignup from "./ConfirmSignup";
import { Modal } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';
import config from '../config';
import { browserName, browserVersion } from "react-device-detect";
import browserConfig from "../browserConfig";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Login(props) {
  const { userHasAuthenticated } = useAppContext();
  const [member_number, setMemberNumber] = useState("");
  const [password, setPassword] = useState("");
  const [formData, setFormData] = useState({ pass: "", user: "" });
  const [validate, setValidate] = useState(false);
  const [openTOU, setOpenTOU] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isSupported, setIsSupported] = useState(true);
  // const history = useHistory();

  function validateForm() {
    return member_number.length > 0 && password.length > 0;

  }

  const acceptTOU = async () => {
    setOpenTOU(false);
    let token = await Auth.currentSession().then(res => res.getIdToken());
    let touBody = JSON.stringify({
      "tou": "Y"
    });
    let touCall = await fetch(config.apiGateway.URL + '/ers/' + token.payload['cognito:username'] + '/tou', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: touBody });
    //let touResponse = await touCall.json();
    supportedBrowser();

  }
  // {this.state.isAuthenticated ? (
  //   this.setState({ redirect: "/EmployerProfile" })
  // ) : (
  //   alert("Incorrect MemberID or Password")
  // )}



  async function declineTOU() {
    setOpenTOU(false);
    await Auth.signOut();
    userHasAuthenticated(false);
  }

  function closeSupported() {
    setIsSupported(true);
  }

  function supportedBrowser() {
    let bName = browserName.valueOf();
    let bVersion = browserVersion.valueOf();
    if (bName.toLowerCase() in browserConfig.supported) {
      //if(browserConfig.supported.hasOwnProperty(bName.toLowerCase())){
      console.log(browserConfig.supported[bName.toLowerCase()]);
      if (!(bVersion >= browserConfig.supported[bName.toLowerCase()])) {
        console.log('unsupported Version');
        setIsSupported(false);
      } else {
        accept();
      }

    } else {
      console.log('unsupported Version');
      setIsSupported(false);
    }
  }

  async function accept() {

    let response = await Auth.currentSession();
    let access = await response.getAccessToken();
    let admin = 0;
    if (access.payload['cognito:groups'] !== undefined) {
      admin = access.payload['cognito:groups'].indexOf('Admin') + 1;
      // console.log(access.payload['cognito:groups'].indexOf('Admin'))
    }

    // console.log(admin);
    if (admin > 0) {
      props.isAdmin.setAdmin(true);
      // console.log(asAdmin);
      props.asAdmin.setAsAdmin(true);
      // props.asAdmin.setAsAdmin(true);
    }
    console.log(validate);
    if(!validate){
      userHasAuthenticated(true);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await Auth.signIn(member_number, password);
      // userHasAuthenticated(true);
      var attributes;
      var verified;
      let user = await Auth.currentAuthenticatedUser();
      attributes = await user.attributes;
      verified = attributes.email_verified;
      console.log(verified);
      if (verified === false) {
        console.log(verified);
        // let host = window.location.origin;
        // window.location.href = host + "/ConfirmSignup?accountid=" + member_number;
        setValidate(true);
      }
      else{
        let token = await Auth.currentSession().then(res => res.getIdToken());

        let touCall = await fetch(config.apiGateway.URL + '/ers/' + token.payload['cognito:username'] + '/tou', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } });
        let touRespose = await touCall.json();

        console.log(touRespose)
        if (touRespose.items[0].tou === "N") {
          setOpenTOU(true);
        } else {
          supportedBrowser();
        }
      }
    } catch (e) {
      if (e.message === "User is not confirmed.") {
        // window.sessionStorage.setItem("confirm", password);
        // let host = window.location.origin;
        // window.location.href = host + "/ConfirmSignup?accountid=" + member_number;
        setValidate(true);
      }
      else {
        alert(e.message);
        // onError(e);
      }
    }
    
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    //<!-- HEADER NAVIGATION -->	
    <div class="title">
      <Modal
        open={openTOU}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* style={{ position: 'absolute', top: '10%', left: '25%', width: '600px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }} */}
        <div >
          {/* <h3>Employer Portal Terms of Use</h3>
              <button style={{ margin: '10px' }} name="back" onClick={this.decline} class="button-green">Decline</button>
              <button name="back" onClick={this.accept} class="button-green">Accept</button> */}
          <TermsAndConditions acceptTOU={acceptTOU} declineTOU={declineTOU} />
        </div>
      </Modal>
      <Modal
        open={!isSupported}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={style}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Outdated or Unsupported browser detected, for the best perfomance of this site, please use Chrome, Firefox, or Edge browsers updated to the latest version.
              </Typography>
              <button style={{ marginTop: '20px' }} name="back" onClick={closeSupported} class="button-green">Cancel</button>
              <button style={{ marginLeft: '20px' }} name="back" onClick={accept} class="button-green">Continue</button>
            </Box>
      </Modal>
        {!validate &&
         <div class="row">
             <div class="column col-7">
        <h2 class="my_spp_auth_form">MyBusiness Employer Portal Log In</h2>
        <form method="POST" class="border-form spin-on-submit my_spp_auth_form" autocomplete="off" onSubmit={handleSubmit}>
          {/* <!-- Note - intentionally visually different from the incorrect credentials message --> */}


          <div class="input required">
              <label for="member_number" class="bold-label ">User ID:
              </label>
              <p style={{ fontSize: '.8em' }}>This Number can be found in your verification email</p>
              <input
                  type="text"
                  name="member_number"
                  value={member_number}
                  style={{ width: '50%' }}
                  onChange={(e) => setMemberNumber(e.target.value)}
              />
          </div>
          <div class="input required">
              <label for="password" class="bold-label ">Password:</label>
              <input type="password"
                     security='disc'
                     style={{ fontFamily: 'text-security-disc', width: '50%' }}
                     name="password"
                     onChange={(e) => setPassword(e.target.value)}
              />
          </div>

          <div class="form-errors">
          </div>

          <Link to={`/ForgotPassword`}>
            <a href="">Forgot your Password?</a>
          </Link>

          <br />
          <div style={{ textalign: "right" }}>
            {/* <Link to={'/EmployerProfile'}> */}
            <button type="submit" value="Submit" class="button-green" disabled={!validateForm()} >Submit</button>
            {/* </Link> */}
          </div>
        </form>

        <h2>Not Registered?</h2>
        <p>
          <Link to={`/RegisterExisting`}>
            <a href="/faq.php#MySPP">Existing Employer</a>
          </Link>
          <br />
          <Link to={`/EmployerRegister`}>
            <a href="/faq.php#MySPP">New Employer</a>
          </Link>
          <br />
          {/* <!-- 1-800-667-7153&nbsp;&nbsp;&mdash;&nbsp;&nbsp; --> */}
          <br />

          <a href="tel:1-800-667-7153">1-800-667-7153</a>&nbsp;&nbsp;&mdash;&nbsp;&nbsp;
          <a href="mailto:employerrequests@saskpension.com">employerrequests@saskpension.com</a>
          <br />
          <strong>Privacy and Terms here</strong> <a href="https://www.saskpension.com/privacy-policy/">https://www.saskpension.com/privacy-policy</a>

        </p>

      </div>
      </div>}
      {validate && <div>
        <ConfirmSignup pw={password} accountid={member_number} />
      </div>}

    </div>
  )
}
