import React from 'react';
// import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
import { Link } from "react-router-dom";
// import ChangePassword from './ChangePassword';
import { withStyles } from '@mui/styles';
import { useAppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default function Logout() {
  const { userHasAuthenticated } = useAppContext();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorPw, setAnchorPw] = React.useState(null);
  // const history = useHistory();

  async function handleLogout() {
    let host = window.location.origin;
    await Auth.signOut();
    // window.localStorage.setItem('employerid', '');
    window.sessionStorage.clear();
    // window.localStorage.setItem('employerid', "");
    // window.localStorage.setItem('isAdmin', false);
    // window.localStorage.setItem('asAdmin', null);
    userHasAuthenticated(false);
    
    // history.push(host + "/Login");
    window.location.href = host + "/Login";
  }

  const StyledMenuItem = withStyles({
      // '.ItemStyle': {
            // fontfamily: "Open Sans, Avenir, sans-serif",
            // lineheight: "1.7",
            // liststyle: "none",
//   fontweight: "bold",
//   boxsizing: "border-box",
//   margin: "0",
//   paddingtop: "0.875em",
//   paddingright: "1.75em",
//   paddingbottom: "15px",
//   paddingleft: "1.75em",
  // fontsize: "0.875em",
  // border: "none",
  // cursor: "pointer"
      // },
  })(MenuItem);

  return (
    <div  style={{marginTop:"-2px"}}>
      
      {/* <Button aria-controls="simple-menu"  style={{color: 'white'}} aria-haspopup="true" onClick={handleClick}>
        Menu
      </Button> */}
      
        <Link title="Logout">
        <StyledMenuItem  onClick={handleLogout}><ExitToAppIcon fontSize="large"/></StyledMenuItem>
        </Link>
      
    </div>
  );
}