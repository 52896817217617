import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { onError } from "../libs/errorLib";
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../config';
import { Modal } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
//import queryString from 'query-string';


class ConfirmSignup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountid: null,
            remit_date: null,
            num_employ: null,
            remit_amount: null,
            email_add: null,
            curr_first: null,
            curr_last: null,
            isConfirm: false,
            employerid: null,
            validAdminFirst: true,
            validAdminLast: true,
            validAdminEmail: true,
            code: 0,
            errorMsg: false,
            isLoading: false,
            existingAdmin: false,
            isLogin: false,
            member_number: null,
            saving: false
        };
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.validateEmployerAdmin = this.validateEmployerAdmin.bind(this);
        this.validEmail = this.validEmail.bind(this);
    }

    resendCode = async (event) => {
        await Auth.resendSignUp(this.props.accountid);

    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    validEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === null || email === '' || !re.test(email)) {
            return false;
        }
        return true;
    }


    async componentDidMount() {
        // const windowUrl = window.location.search;
        // const params = new queryString.parse(windowUrl);
        // if (params['accountid'] !== undefined) {
        //     this.setState({ accountid: params['accountid'] })
        // }
        //         var attributes;
        //         var verified;
        //         let user = await Auth.currentAuthenticatedUser();
        //         attributes = await user.attributes;

        // console.log(user);
        //         this.setState({
        //             email_add: attributes.email,
        //             curr_first: attributes.given_name,
        //             curr_last: attributes.family_name,
        //             accountid: user.username
        //         });
    }

    async validateEmployerAdmin() {

        let postBody = JSON.stringify({
            "employerid": this.state.employerid,
            "givennames": this.state.curr_first,
            "surname": this.state.curr_last,
            "email": this.state.email_add
        });

        let response = await fetch(config.apiGateway.URL + '/validateExistingAdministrator', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
            .then(response => response.json())
            .catch((error) => {
                console.log(error)
            });
        // console.log(response);

        if (response.validate === 1) {
            return 1;
        }
        else {
            return 0;
        }
    }


    handleConfirm = async (event) => {
        // const { history } = this.props;
        // if (this.validateEmployerAdmin() === 1) {
            this.setState({saving: true});
            // var cont;
        try {
            await Auth.confirmSignUp(this.props.accountid, this.state.confirm_code);
            
            await Auth.signIn(this.props.accountid, this.props.pw);
            var attributes;
            let userInfo = await Auth.currentAuthenticatedUser();
            attributes = await userInfo.attributes;
            this.setState({ curr_first: attributes.given_name, curr_last: attributes.family_name, email_add: attributes.email });
            let cont = await this.validateEmployerAdmin();
            if (cont === 1) {
                let token = await Auth.currentSession().then(res => res.getIdToken());
                // console.log(token);
                let user = await Auth.currentUserInfo();
                let accountBody = JSON.stringify({
                    "cognito_uuid": user.attributes.sub,
                    "cognito_identity_uuid": user.id,
                    "roleid": 4,
                })
                await fetch(config.apiGateway.URL + '/ers/' + this.props.accountid + '/account', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: accountBody })
                // console.log(accountResp);

                let postBody = JSON.stringify({
                    "givennames": attributes.given_name,
                    "surname": attributes.family_name,
                    "email": attributes.email,
                })
                // console.log(postBody)
                let response = await fetch(config.apiGateway.URL + '/ers/' + this.props.accountid + '/' + this.state.employerid + '/addemployeradmin', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody })
                    .then(response => response.json());
                // .then(response => response.json());
                // .then(json => console.log(json));
                // .then(json => alert(JSON.stringify(json)));
                // console.log(response.message);

                if (this.state.employerid === '1') {
                    let attri = await Auth.currentAuthenticatedUser();
                    let postBody = JSON.stringify({
                        "userPoolId": attri.pool.userPoolId,
                        "userName": user.username
                    })
                    await fetch(config.apiGateway.URL + '/addAdminUserToGroup', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody })
                        .then(response => response.json()).catch((error) => {
                            // console.log(error);
                        });;
                }
                if (response.message === "Employer Info Verified") {

                    // console.log('change page');
                    // history.push("/EmployerProfile");
                    window.location.href = window.location.origin + "/EmployerProfile";
                    this.setState({saving: false});
                } else {
                    // console.log('Error confirming sign up. Please contact the system administrator');
                    this.setState({ errorMsg: true, saving: false });
                }
            } else {
                this.setState({ errorMsg: true, saving: false });
            }
        } catch (error) {
            this.setState({ errorMsg: true, saving:false });
            // console.log('Error confirming sign up. Please contact the system administrator', error)
        }
        // }
    }

    handleClose = () => {
        this.setState({ errorMsg: false })
    }

    render() {
        return (
            //<!-- HEADER NAVIGATION -->	
            <div class="title">
                <Snackbar open={this.state.errorMsg} onClose={this.handleClose}>
                    <SnackbarContent style={{
                        backgroundColor: '#f44336',
                    }}
                        message={<span id="client-snackbar">Error confirming sign up. Please contact the system administrator</span>}
                        action={
                            <React.Fragment>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={this.handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </Snackbar>
                <Modal
                    open={this.state.saving}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress size={100} />
                    </div>
                </Modal>
                {this.state.isLoading && <div>
                    <CircularProgress />
                </div>}
                {!this.state.isLoading && <div class="row">

                    <div class="column col-6 my_spp_auth_form">
                        <h2>Confirm Signup</h2>
                        <form method="POST" class="border-form spin-on-submit" autoComplete="off">
                            <div class="input required">
                                <label for="employerid" class="bold-label ">Employer ID:</label>
                                <input type="number" onWheel={(e) => e.target.blur()} name="employerid" class="wide-input quantity" value={this.state.employerid} autoComplete="off" onChange={this.myChangeHandler} />
                            </div>
                            {/* <div class="input">
                                <label for="curr_first" class="bold-label ">First Name:</label>
                                <input type="text" name="curr_first" class="wide-input" disabled={true} value={this.state.curr_first} autoComplete="off" onChange={this.myChangeHandler} />
                            </div>
                            <div class="input">
                                <label for="curr_last" class="bold-label ">Last Name:</label>
                                <input type="text" name="curr_last" class="wide-input" readOnly={true} value={this.state.curr_last} autoComplete="off" onChange={this.myChangeHandler} />
                            </div> */}
                            {/* <div class="input required">
                                <label for="email_add" class="bold-label ">Email Address:</label>
                                <input type="text" name="email_add" style={{ border: (this.state.validAdminEmail || this.validEmail(this.state.email_add)) ? '' : '1px solid #ff0000' }} class="wide-input" value={this.state.email_add} autoComplete="off" onChange={this.myChangeHandler} />
                            </div> */}

                            <br />

                            <label class="bold-label ">Check your email for the 6-digit verification code</label>
                            <div class="input required">
                                <label for="confirm_code" class="bold-label ">Verification Code:</label>
                                <input type="text" name="confirm_code" class="wide-input" value={this.state.confirm_code} onChange={this.myChangeHandler} autoComplete="new-password" maxLength="6"/>
                                <button type="button" style={{ marginTop: '10px' }} name="register" class="button-green" onClick={this.resendCode}>Resend verification code</button>
                            </div>
                            <div class="loginbutton" style={{ textalign: 'right' }}>
                                <button type="button" name="register" class="button-green" onClick={this.handleConfirm}>Verify</button>
                            </div>
                        </form>

                    </div>
                </div>}

            </div>
        );
    }
}

export default ConfirmSignup