import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';

export default function ErrorSnackBar({ open, handleClose, message }) {

    return (
     
  
        <Snackbar open={open} onClose={handleClose}>
            <SnackbarContent style={{
                backgroundColor: '#f44336',
            }}
                message={<span id="client-snackbar">{message}</span>}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </Snackbar>

    )
  }