function PostalCodeCheck(postalCode, country) {

    if (!postalCode) {

       return false;

   }



   postalCode = postalCode.toString().trim();



   var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");

   var ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);

   var uk = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;

   var other = new RegExp("^\\d+$");



   if (country == "US") {

       if (us.test(postalCode.toString())) {

           return true;

       }

   }

   else if (country == "CA") {

       if (ca.test(postalCode.toString())) {

           return true;

       }

   }

   else if (country == "UK") {

       if (uk.test(postalCode.toString())) {

           return true;

       }

   }

   else {

       if (other.test(postalCode.toString())) {

          return true

       }

   }



   return false;

}export default PostalCodeCheck