import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { onError } from "../libs/errorLib";
// import queryString from 'query-string';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../config';
import { Modal } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


class RegisterExisting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountid: null,
      remit_date: null,
      num_employ: null,
      remit_amount: null,
      email_add: null,
      password: null,
      password_verify: null,
      curr_first: null,
      curr_last: null,
      isConfirm: false,
      employerid: null,
      passwordValid: true,
      passwordVerifyValid: true,
      validAdminFirst: true,
      validAdminLast: true,
      validAdminEmail: true,
      code: 0,
      errorMsg: false,
      isLoading: false,
      existingAdmin: false,
      isLogin: false,
      member_number: null,
      passwordType: 'password',
      passwordregex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&=+\/\\,><\':;|_~`])\S{8,99}$/,
      openTOU: false,
      accepted: false
    };
    this.handleCommit = this.handleCommit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.myPasswordHandler = this.myPasswordHandler.bind(this);
    this.myPasswordVerifyHandler = this.myPasswordVerifyHandler.bind(this);
    this.validEmail = this.validEmail.bind(this);
    this.passwordVerifyValidate = this.passwordVerifyValidate.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.acceptTOU = this.acceptTOU.bind(this);
    this.declineTOU = this.declineTOU.bind(this);
  }

  validEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === null || email === '' || !re.test(email)) {
      return false;
    }
    return true;
  }

  acceptTOU = () => {
    this.setState({ openTOU: false, accepted: true });
    this.handleCommit();
  }

  handleTOU = () => {
    if (this.state.accepted) {
      this.acceptTOU();
    } else {
      this.setState({ openTOU: true });
    }
  }

  declineTOU = () => {
    this.setState({ openTOU: false });
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  myPasswordHandler = (e) => {
    this.setState({ password: e.target.value });
    if (this.state.passwordregex.test(e.target.value) === true) {
      this.setState({ passwordValid: true });
    } else {
      this.setState({ passwordValid: false });
    }
  };

  myPasswordVerifyHandler = (e) => {
    this.setState({ password_verify: e.target.value });
    if (e.target.value === this.state.password) {
      this.setState({ passwordVerifyValid: true });
    } else {
      this.setState({ passwordVerifyValid: false });
    }
  };

  passwordVerifyValidate() {
    if (this.state.password === this.state.password_verify) {
      return true;
    } else {
      this.setState({ passwordVerifyValid: false });
      return false;
    }
  }


  validateForm = () => {
    // console.log("validating Form")
    if (this.state.passwordValid & this.state.password !== null & this.state.password !== '') {
      if (this.state.passwordVerifyValid & this.passwordVerifyValidate() & this.state.password_verify !== null & this.state.password_verify !== '') {
        if (this.state.employerid !== null & this.state.employerid !== '') {
          if (this.state.curr_first !== null & this.state.curr_first !== '') {
            if (this.state.curr_last !== null & this.state.curr_last !== '') {
              if (this.state.email_add !== null & this.state.email_add !== '' && this.validEmail(this.state.email_add)) {
                return true;
              } else return false;
            } else return false;
          } else return false;
        } else return false;
      } else return false;
    } else return false;
  }

  // async componentDidMount() {
  //   const windowUrl = window.location.search;
  //   const params = new queryString.parse(windowUrl);
  //   console.log(params['code'])
  //   if(params['code'] !== undefined){
  //     this.setState({code: params['code']});
  //   let token = await Auth.currentSession().then(res=> res.getIdToken() );
  //   // console.log(token);
  //   // console.log(token.payload['cognito:username'])

  //   let referenceJSON = await fetch('https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod/ers/' + token.payload['cognito:username'] + '/' + this.props.employerid + '/' + params['code'] + '/employeradministrator', { method: 'GET', headers: { 'Authorization': token.getJwtToken() } })
  //   .then(response => response.json());
  //   // console.log(authorities);
  //   this.setState({
  //     curr_first: referenceJSON.givennames,
  //     email_add: referenceJSON.email,
  //     curr_last: referenceJSON.surname
  //   });
  // }
  // }

  handleConfirm = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.confirmSignUp(this.state.accountid, this.state.confirm_code);
      await Auth.signIn(this.state.accountid, this.state.password);

      let token = await Auth.currentSession().then(res => res.getIdToken());
      // console.log(token);
      let user = await Auth.currentUserInfo();
      let accountBody = JSON.stringify({
        "cognito_uuid": user.attributes.sub,
        "cognito_identity_uuid": user.id,
        "roleid": 4,
        "tou": "Y"
      })
      await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/account', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: accountBody })
      // console.log(accountResp);

      let postBody = JSON.stringify({
        "givennames": this.state.curr_first,
        "surname": this.state.curr_last,
        "email": this.state.email_add,
      })
      // console.log(postBody)
      let responseCall = await fetch(config.apiGateway.URL + '/ers/' + this.state.accountid + '/' + this.state.employerid + '/addemployeradmin', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody });
      // .then(response => response.json());
      let response = await responseCall.json();
      // .then(response => response.json());
      // .then(json => console.log(json));
      // .then(json => alert(JSON.stringify(json)));
      // console.log(response.message);

      if (this.state.employerid === '1') {
        let attri = await Auth.currentAuthenticatedUser();
        let postBody = JSON.stringify({
          "userPoolId": attri.pool.userPoolId,
          "userName": user.username
        })
        await fetch(config.apiGateway.URL + '/addAdminUserToGroup', { method: 'PUT', headers: { 'Authorization': token.getJwtToken(), 'Content-Type': 'application/json' }, body: postBody })
          .catch((error) => {
            // console.log(error);
          });;
      }
      if (response.message === "Employer Info Verified") {

        // console.log('change page');
        // history.push("/EmployerProfile");
        window.location.href = window.location.origin + "/EmployerProfile";
      } else {
        // console.log('Error confirming sign up. Please contact the system administrator');
        this.setState({ errorMsg: true, isLoading: false });
      }
    } catch (error) {
      this.setState({ errorMsg: true, isLoading: false });
      // console.log('Error confirming sign up. Please contact the system administrator', error)
    }
    this.setState({ isLoading: false });
  }

  checkErrors() {
    // console.log('checkingErrors')
    // console.log(this.state.employerName);
    let newerrors = [];
    // this.setState({ errors: newerrors },() => { console.log(this.state.errors)});
    if (!this.state.passwordValid || this.state.password === null || this.state.password === '') {
      newerrors.push('Invalid Password');
    }
    if (!this.state.passwordVerifyValid || this.state.password_verify === null || this.state.password_verify === '' || this.state.password !== this.state.password_verify) {
      newerrors.push('Password verification does not match');
    }
    if (this.state.curr_first === null || this.state.curr_first === '') {
      newerrors.push('Administrator First name cannot be blank');
      this.setState({ validAdminFirst: false });
    } else this.setState({ validAdminFirst: true });
    if (this.state.curr_last === null || this.state.curr_last === '') {
      newerrors.push('Administrator Last Name cannot be blank');
      this.setState({ validAdminLast: false });
    } else this.setState({ validAdminLast: true });
    if (this.state.email_add === null || this.state.email_add === '' || !this.validEmail(this.state.email_add)) {
      newerrors.push('Administrator Email is invalid');
      this.setState({ validAdminEmail: false });
    } else this.setState({ validAdminEmail: true });
    if (newerrors !== null & newerrors !== '') {
      this.setState({ hasErrors: true, errors: newerrors }, () => {
        // console.log(this.state.errors)
      });
    }
  }

  handleClose = () => {
    this.setState({ errorMsg: false })
  }

  handleCommit = async (event) => {
    if (this.validateForm()) {
      this.setState({ isLoading: true })
      let postBody = JSON.stringify({
        "employerid": this.state.employerid,
        "givennames": this.state.curr_first,
        "surname": this.state.curr_last,
        "email": this.state.email_add
      });
      let response = {};
      try {
        let responseCall = await fetch(config.apiGateway.URL + '/validateExistingAdministrator', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
        // .then(response => response.json())
        response = await responseCall.json();
        // .catch((error) => {
        //   console.log(error)
        // });
        // console.log(response);
      } catch (error) {
        console.log(error)
      };
      if (response.validate === 1) {

        try {
          let postBody = JSON.stringify({
            "employername": null
          })
          let accountCall = await fetch(config.apiGateway.URL + '/createAccount', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
          // .then(response => response.json());
          let accountJSON = await accountCall.json();
          // .then(json => console.log(json));
          //   .then(json => this.setState({ accountid: json.accountid.toString() }));
          this.setState({ accountid: accountJSON.accountid.toString() });
          // console.log(this.state.accountid)

          await Auth.signUp({
            username: accountJSON.accountid.toString(),
            password: this.state.password,
            attributes: {
              given_name: this.state.curr_first,
              family_name: this.state.curr_last,
              email: this.state.email_add,
            }
          })
          // .catch(error => {
          //   onError('Invalid Email, or email already used with another account');
          // })
          this.setState({
            isConfirm: true,
            isLoading: false
          });
        } catch (e) {
          console.log(e);
          console.log(e.message);
          // if(e.message === 'Password cannot be empty'){
          if (e.name === 'InvalidLambdaResponseException') {
            onError('An administrator with that email already exists, please make sure the spelling of their First and Last names are correct.');
          } else {
            onError(e.message);
          }

          this.setState({ isLoading: false });
        }
      } else {
        this.setState({ errorMsg: true, isLoading: false });
      }
    } else {
      this.checkErrors();
    }
  }

  // handleCommit = async (event) => {
  //   if (this.validateForm()) {
  //     let postBody = JSON.stringify({
  //       "employerid": this.state.employerid,
  //       "givennames": this.state.curr_first,
  //       "surname": this.state.curr_last,
  //       "email": this.state.email_add
  //     });

  //     let response = await fetch('https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod/validateExistingAdministrator', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: postBody })
  //     .then(response => response.json())
  //     .catch((error) => {
  //       console.log(error)
  //     });
  //     console.log(response);

  //   } else {
  //     this.checkErrors();
  //   }
  // }
  showPassword() {
    this.setState({ passwordType: 'text' })
  }

  hidePassword() {
    this.setState({ passwordType: 'password' })
  }

  render() {
    return (
      //<!-- HEADER NAVIGATION -->	
      <div class="title">
        <Modal
          open={this.state.isLoading}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress size={100} />
          </div>
        </Modal>
        <Modal
          open={this.state.openTOU}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {/* style={{ position: 'absolute', top: '10%', left: '25%', width: '600px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }} */}
          <div >
            {/* <h3>Employer Portal Terms of Use</h3>
              <button style={{ margin: '10px' }} name="back" onClick={this.decline} class="button-green">Decline</button>
              <button name="back" onClick={this.accept} class="button-green">Accept</button> */}
            <TermsAndConditions acceptTOU={this.acceptTOU} declineTOU={this.declineTOU} />
          </div>
        </Modal>
        <Snackbar open={this.state.errorMsg} onClose={this.handleClose}>
          <SnackbarContent style={{
            backgroundColor: '#f44336',
          }}
            message={<span id="client-snackbar">Error confirming sign up. Please contact the system administrator</span>}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
        {this.state.isLoading && <div>
          <CircularProgress />
        </div>}
        {!this.state.isLoading && <div class="row">

          {!this.state.isConfirm && <div class="column col-6 my_spp_auth_form">
            <h2>Register Employer</h2>
            <form method="POST" class="border-form spin-on-submit" autocomplete="off">
              <div class="input required">
                <label for="employerid" class="bold-label ">Employer ID:</label>
                <input type="number" onWheel={(e) => e.target.blur()} name="employerid" class="wide-input quantity" value={this.state.employerid} autoComplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input required">
                <label for="curr_first" class="bold-label ">First Name:</label>
                <input type="text" name="curr_first" class="wide-input" value={this.state.curr_first} autoComplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input required">
                <label for="curr_last" class="bold-label ">Last Name:</label>
                <input type="text" name="curr_last" class="wide-input" value={this.state.curr_last} autoComplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input required">
                <label for="email_add" class="bold-label ">Email Address:</label>
                <input type="text" name="email_add" style={{ border: (this.state.validAdminEmail || this.validEmail(this.state.email_add)) ? '' : '1px solid #ff0000' }} class="wide-input" value={this.state.email_add} autoComplete="off" onChange={this.myChangeHandler} />
              </div>
              <div class="input required">
                <label for="password" style={{ display: "contents" }} class="bold-label ">Password:</label>{this.state.passwordType === 'password' && <IconButton aria-label="sort" title="Show Password" size="medium" onClick={() => this.showPassword()}>
                  <VisibilityIcon color="black" fontSize="inherit" />
                </IconButton>}
                {this.state.passwordType === 'text' && <IconButton aria-label="sort" title="Hide Password" size="medium" onClick={() => this.hidePassword()}>
                  <VisibilityOffIcon color="black" fontSize="inherit" />
                </IconButton>}
                <input type={this.state.passwordType} name="password" style={{ border: this.state.passwordValid ? '' : '1px solid #ff0000' }} class="wide-input" minLength="8" pattern={this.state.passwordregex} autoComplete="new-password" onChange={this.myPasswordHandler} required />

                <div style={{ fontsize: "0.8em" }}>At least 8 characters and must include lowercase, uppercase, numbers, and a special character (^ $ * . [ ] {"{}"} ( ) ? - " ! @ # % & / \ , {"<>"} ' : ; | _ ~ ` = +)</div>
              </div>
              <div class="input required">
                <label for="password_verify" class="bold-label ">Password (verify):</label>
                <input type={this.state.passwordType} name="password_verify" style={{ border: this.state.passwordVerifyValid ? '' : '1px solid #ff0000' }} class="wide-input" onChange={this.myPasswordVerifyHandler} autoComplete="new-password" required />
              </div>

              <div class="form-errors">
              </div>

              <br />
              <div class="loginbutton" style={{ textalign: 'right' }}>
                {/* <Link to={`/EmployerProfile`}>
                    <button type="submit" name="register" class="button-green" onClick={this.handleCommit}>Register Account</button>
                    </Link> */}
                {!this.state.isConfirm && <button type="button" name="register" class="button-green" onClick={this.handleTOU}>Create Account</button>}
              </div>
            </form>
          </div>}
          {this.state.isConfirm && <div class="column col-6 my_spp_auth_form">
            <form method="POST" class="border-form spin-on-submit" onSubmit={this.handleConfirm} autocomplete="off">
              <label class="bold-label ">Check your email for the 6-digit verification code</label>
              <div class="input required">
                <label for="confirm_code" class="bold-label ">Verification Code:</label>
                <input type="text" name="confirm_code" class="wide-input" value={this.state.confirm_code} onChange={this.myChangeHandler} autoComplete="new-password" maxLength="6"/>
              </div>
              <div class="loginbutton" style={{ textalign: 'right' }}>
                {this.state.isConfirm && <button type="submit" name="submit" class="button-green" onClick={this.handleConfirm}>Verify</button>}
              </div>
            </form>
          </div>}
        </div>}

      </div>
    );
  }
}

export default RegisterExisting