

export  const validatePostalCodeAgainstProvince = function(postalcode, province ){
    let provincePostalCode = {
        R:"MB",
        S:"SK",
        T:"AB",
        E:"NB",
        V:"BC",
        M:"ON",
        P:"ON",
        K:"ON",
        N:"ON",
        X:"NT",
        L:"ON",
        C:"PE",
        B:"NS",
        Y:"YT",
        A:"NL",
        G:"QC",
        H:"QC",
        J:"QC"
    };

    if (postalcode === null || province === null)
        return false;
    let firstLetter = postalcode.substring(0,1);
    
    if (provincePostalCode[firstLetter] === province)
        return true;
    return false;


}

export const normalizeSIN = function (value) {

    if (!value) return value;
  
    const currentValue = value.replace(/ /g, '').replace(/[\D+]/g, '').slice(0, 9);
    const cvLength = currentValue.length;
  
    
  
    if (cvLength < 4) 
        return currentValue;
    if (cvLength < 7) 
        return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(6, 9)}`;
   
}

export const validateSIN = function (value) {

    if (value === undefined || value.replace(/ /g, '').length < 9) {
        return false;
    }
    let sin = value.replace(/ /g, '').replace(/[\D+]/g, '').slice(0, 9);

    let sin1 = Number(sin[1]);
    let sin3 = Number(sin[3]);
    let sin5 = Number(sin[5]);
    let sin7 = Number(sin[7]);
    let sin12 = 0;
    let sin32 = 0;
    let sin52 = 0;
    let sin72 = 0;
    sin1 = sin1 * 2;
    sin3 = sin3 * 2;
    sin5 = sin5 * 2;
    sin7 = sin7 * 2;
    if (sin1 > 9) {
        sin12 = sin1 % 10;
        sin1 = 1;
    }
    if (sin3 > 9) {
        sin32 = sin3 % 10;
        sin3 = 1;
    }
    if (sin5 > 9) {
        sin52 = sin5 % 10;
        sin5 = 1;
    }
    if (sin7 > 9) {
        sin72 = sin7 % 10;
        sin7 = 1;
    }
    let total = Number(sin[0]) + sin1 + sin12 + Number(sin[2]) + sin3 + sin32 + Number(sin[4]) + sin5 + sin52 + Number(sin[6]) + sin7 + sin72 + Number(sin[8]);

    if (total % 10 > 0) {
        return false;
    } else {
        return true;
    }
}

