import React from 'react';

export default function MaintenanceNotice(props) {
    // const [message, setMessage] = useState('this is a message');
    // const [showMessage, setShowMessage] = useState(false);
    // useEffect(() => {
    //     showNotice()
    //   }, []);
    
      // async function showNotice() {
      //   let noticeS3 = await fetch('https://9t91t63n0c.execute-api.ca-central-1.amazonaws.com/prod/getNoticeMessage', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
      //       .then(response => response.json());
      //   console.log(noticeS3);
      //   setMessage(noticeS3.notice);
      // }


// var startTime = '15:10:10';
// var endTime = '22:30:00';

// currentDate = new Date()   

// startDate = new Date(currentDate.getTime());
// startDate.setHours(startTime.split(":")[0]);
// startDate.setMinutes(startTime.split(":")[1]);
// startDate.setSeconds(startTime.split(":")[2]);

// endDate = new Date(currentDate.getTime());
// endDate.setHours(endTime.split(":")[0]);
// endDate.setMinutes(endTime.split(":")[1]);
// endDate.setSeconds(endTime.split(":")[2]);


// valid = startDate < currentDate && endDate > currentDate
return (
    <div class="row">
            <div class="border-form" style={{backgroundColor:'#9fcf67', margin:'0px', paddingLeft:'26.88px'}}>
                <h2 style={{textAlign:'center'}}>MyBusiness Maintenance</h2>
                <p style={{fontSize:'1em'}}><strong>{props.message}</strong></p>
        </div>
    </div>
  );

}