import React from 'react';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PhoneIcon from '@mui/icons-material/Phone';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';


export default function Help(props) {


    return (
        <div>
            {props.location === "/EmployerProfile" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon  fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon  fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Employer Profile Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This Page is used to edit the employer information and can be used to add an authority to the desired account. Adding an authority allows that person to administer your account.</p>
                    <ul>
                        <li style={{color: 'black'}}>The actions and icons seen on the page are detailed below:</li>
                    <div>
                    &nbsp;&nbsp;<EditIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This Pencil icon on the screen allows you to edit the fields on the page.</p>
                    </div>
                    <div>
                    &nbsp;&nbsp;<SaveIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This save icon saves your changes made to the fields.</p>
                    </div>
                    <div>
                    &nbsp;&nbsp;<UndoIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This undo icon reverts the information in the fields to its previously saved information.</p>
                    </div>
                    </ul>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/ManageEmployees" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Manage Employees Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to add, edit, and remove employees for whom you will be remitting pension contributions for. On this page, you can also filter the employees based on their assigned payment profiles.
                        To add an employee, you must be able to provide a valid social insurance number (SIN), last name, first name, and valid email address. Please note that the employee, when added, will reveive an email from SPP to begin the new member enrolment process.
                    </p>
                    <ul>
                        <li style={{color: 'black'}}>The actions and icons seen on the page are detailed below:</li>
                    <div>
                    &nbsp;&nbsp;<RemoveCircleOutlineIcon color='secondary' fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px', lineHeight:'1'}}>The remove icon can be used to remove an employee. After clicking the icon, the row<br /> will turn red and you will be required to press the save button to remove the employee.</p>
                    </div>
                    <div>
                    &nbsp;&nbsp;<UndoIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This undo icon can be pressed to stop the removal of the employee.</p>
                    </div>
                    </ul>
                    <p style={{color: 'red'}}>The save button needs to be clicked to have your changes take effect.</p>
                    <p style={{lineHeight:'1'}}>Add in Bulk: The accepted file format is .CSV only. The first row of the document will be considered as headers, and they need to be formatted as follows:</p>
                    <p style={{lineHeight:'1'}}>|  <b>givenname</b>  |  <b>surname</b>  |  <b>sin</b>  |  <b>email</b>  |</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/ContributionProfile" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Payment Methods Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to add, edit and remove methods of payment that you want to use for making pension contributions for your employees. 
                    For each method of payment you wish to use, you can assign available employees to that payment method. All payment methods must have a nickname (for your reference), remittance frequency 
                    (how often the payment will be made), date for the next remittance (if the payment is automatic), remittance report frequency (how often you want to receive a report), 
                    and a source for payment (see below for details). Additionally, if the payment method is Ceridian, you will have to include your Ceridian payroll number so that
                    SPP can properly register the arrangement with Ceridian.
                    </p>
                    <ul>
                        <li style={{color: 'black'}}>The supported payment methods are detailed below:</li>
                        <p>Cheque - If you intend to mail cheques to the SPP, please select this option.</p>
                        <p style={{lineHeight:'1'}}>Credit Card - This payment method will require you to manually create a remittance in the remit screen using ad hoc credit card information (Card number, expiration date, and CVC). Payment will happen in real time.</p>
                        <p style={{lineHeight:'1'}}>EFT - If you intend to send SPP funds via Electronic File Transfer or online banking, please select this option.</p>
                        <p style={{lineHeight:'1'}}>Pre-Authorized Credit Card (PCC) - By selecting this payment method, you will be directing the SPP to automatically charge your credit card based on your inputted amount, the date of remittance, and remittance frequency.
                        In order to add your PCC, you will need to add the credit card details: credit card number, expiration date, CVV, as well as answer questions regarding your signatory status for the card. You will need to have provided an Authority under your Employer Profile.</p>
                        <p style={{lineHeight:'1'}}>Pre-Authorized Contribution (PAC) - By selecting this payment method, you will be directing the SPP to automatically debit your bank account based on your inputted amount, the date of remittance, and remittance frequency. In order to enable this, 
                        you will need to add the following account information: your institution number, transit number, account number, as well as image containing a void cheque.</p>
                    </ul>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/AdministratorProfile" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Administrator Profile Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to edit the administrator information and can be used to add an administrator to the desired account. Adding an administrator allows that person to administer your account and create remittances on your behalf.</p>
                    <ul>
                        <li style={{color: 'black'}}>The actions and icons seen on the page are detailed below:</li>
                    <div>
                    &nbsp;&nbsp;<EditIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>The Pencil icon allows you to edit the fields on the page.</p>
                    </div>
                    <div>
                    &nbsp;&nbsp;<SaveIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>The save icon saves changes made to the fields.</p>
                    </div>
                    <div>
                    &nbsp;&nbsp;<UndoIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>The undo icon reverts the information in the fields to previously saved information.</p>
                    </div>
                    </ul>
                    <p style={{lineHeight:'1'}}>Adding an administrator requires a first and last name and an email that is validated within the system.</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/SecureMessaging" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Secure Messaging Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to send and receive secure messages and attachments between employers, admins, and SPP administrators. Clicking on the attachment will begin a download to your downloads folder.</p>
                    <p style={{lineHeight:'1'}}>All messages on behalf of an employer will be directed to an SPP administrator.</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/Remit" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Remit Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to report on prior remittances and to create new remittances.</p>
                    <p style={{lineHeight:'1'}}>All payment methods will produce a report of posted remittances based on the date found in the dropdown on the page. For the remittances that require active involvement (cheque, credit card, and EFT) 
                    there is a "New Remit" button that brings you to a section where the employee's individual amounts are entered and submitted.</p>

                    
                    </Paper> 
                    <strong><p>Bulk remittance upload</p></strong>
                    <Paper elevation={3} style={{margin: '10px'}}>
        
                    
                        <p style={{lineHeight:'1'}}> 
                            Clicking the "Add in bulk" button will allow you to fill in this form with a CSV file. This process supports filling in the remittance amounts, enroling new employees,
                            adding new spouses, adding existing employees and spouses to the current payment method and removing not included employees from the payment method.
                        </p>
                        <strong><p>Specifications:</p></strong>
                        <ul >
                            <li style={{color:'black'}}>
                                Required: A header row with the columns:
                                <ul >
                                    <li style={{color:'black'}}>
                                    sin, surname, givennames, email, amount, spouse_sin, spouse_surname, spouse_givennames
                                    </li>
                                </ul>
                            </li>
                            <li style={{color:'black'}}>Required values: sin, amount</li>
                            <li style={{color:'black'}}>Optional values: 
                                <ul >
                                    <li style={{color:'black'}}>
                                    surname, givennames, email are required for new employees but otherwise can be left blank
                                    </li>
                                    <li style={{color:'black'}}>
                                    spouse_sin is required for spousal remittances but otherwise can be left blank
                                    </li>
                                    <li style={{color:'black'}}>
                                    spouse_surname and spouse_givennames is required for new spouses but otherwise can be left blank
                                    </li>
                                </ul>
                            </li>
                        </ul> 
                        <p style={{lineHeight:'1'}}> 
                            Once a valid CSV is uploaded, you will be presented with a dialog screen showing you the values and amounts in the file. It's broken into four sections, the first
                            being your remittances where the employee/spouse already exists in the payment method, the second is employees/spouses that will be added to the payment method. The third
                            is new employee/spouse remittances, and lastly the fourth is a list of the employee/spouses that will be removed from the payment method. If there aren't any validation errors,
                            you can click OK to fill in the remit screen with the amounts on screen. At this point, payment method and employee/spouse changes will be processed.
                        </p>
                        <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                      
                    </Paper> 
                </div>
            </div>}
            {props.location === "/Documents" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Documents Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used for accessing documents associated with your account. These documents can be sorted by name, date uploaded, or size.</p>
                    <ul>
                        <li style={{color: 'black'}}>The main action that can be done on this page is detailed below:</li>
                    <div>
                    &nbsp;&nbsp;<CloudDownloadIcon fontSize='large' /><p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This download icon will place the document in the Downloads folder on your computer.</p>
                    </div>
                    </ul>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/AdminRemitReport" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Remittances Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to approve pending remittances from employers and to produce reports based on the posted remittances.</p>
                    <ul>
                        <li style={{color: 'black'}}>These two tasks are split into the buttons seen below:</li>
                    <div>
                    <button disabled={true} style={{backgroundColor:'#9fcf67', color:'black'}} >Unposted</button>&nbsp;&nbsp;<p style={{lineHeight:'1', display:'inline-flex', position: 'fixed', marginTop:'5px', width:'600px'}}>This page will be populated with any pending remittances that require approval before posting. The unposted PAD and PCC profiles will be grouped by date under the remittance batches dropdown.</p>
                    </div>
                    <div>
                    <br/>
                    <button disabled={true} style={{backgroundColor:'#9fcf67', color:'black'}} >Posted</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p style={{lineHeight:'1', display:'inline-flex', position: 'fixed', marginTop:'5px', width:'600px'}}>These View-only reports will be grouped to view by the employer. The reports for cheque, credit card, and EFT remittances will be generated based on a selected date range and the PAD and PCC reports will be generated based on their remittance batch date.</p>
                    </div>
                    </ul>
                    <br/>
                    <br/>
                    <ul>
                        <li style={{color: 'black'}}>The actions and icons seen on the page are detailed below:</li>
                        <div>
                    &nbsp;&nbsp;<ChangeHistoryIcon fontSize='large' />&nbsp;&nbsp;<p style={{display:'inline-flex', position: 'fixed', marginTop:'5px'}}>This icon can be clicked to see a list of changes to the employer since the last remittance.</p>
                    </div>
                    </ul>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/PayrollProvider" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Payroll Provider Upload Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used to upload and process Ceridian files as well as view priorly processed Ceridian documents.</p>
                    <p style={{lineHeight:'1'}}>After choosing and uploading a Ceridian file you will be ......*need to finish*</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/AddEmployer" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Add Employer Page</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used by SPP admins to add new employers to the ERS system. The admin will add the employer information and thier own administrators information to create and gain access to the account.</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/AdminDashboard" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Dashboard</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used by SPP admins to view read-only reports for upcoming Pre-Authorized PAC or PCC remittances. You can select the payment method and the date in which the remittances will be generated and posted to see the data listed below.</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
            {props.location === "/Maintenance" && <div>
                <div style={{ background: "#bbbbbb", padding: "8px 0", display: 'inline-flex', minWidth: '750px', height: '50px' }} >
                    <LiveHelpIcon fontSize="medium" style={{ marginLeft: '10px', marginTop: '5px' }} />
                    <h3 style={{ paddingLeft: "10px" }}>ERS Support</h3>
                    <PhoneIcon fontSize="medium" style={{ marginLeft: '370px', marginTop: '5px' }} />
                    <h4><a href="tel:18006677153" style={{ color: 'black' }} onClick="ga('send', 'event', '/my_spp/index.php', 'click', 'phone_number');">1 (800) 667-7153</a></h4>
                </div>
                <strong><p>Maintenance</p></strong>
                <div>
                <Paper elevation={3} style={{margin: '10px'}}>
                    <p style={{lineHeight:'1'}}>This page is used by SPP admins to set the system into a scheduled maintenance mode. By selecting the message type, setting a message, and the start and end times you will be able to close off the system to all users. A preview of your message can be viewed by pressing show example button.</p>
                    <p style={{lineHeight:'1'}}>If you require any additional help with this page, please contact SPP at the phone number shown in the top right corner of this dialog box.</p>
                    </Paper> 
                </div>
            </div>}
        </div>
    );

}