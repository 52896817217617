import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function ProvinceSelect(props) {

  const handleChange = (event) => {
    props.setProvince(event.target.value);
  };

  return (
    
    <div>
        <InputLabel id="province-selector-label">Province</InputLabel>
        <Select
          sx={{ 
            backgroundColor: props.disabled? "#f6f6f6" : "white", 
            color: "black",
            border:props.error ? '1px solid #ff0000' : ''
          }} 
          labelId="province-selector-label"
          id="province-selector-select"
          value={props.province||""}
          label="Province"
          onChange={handleChange}
          disabled={props.disabled}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={'AB'}>Alberta</MenuItem>
          <MenuItem value={'BC'}>British Columbia</MenuItem>
          <MenuItem value={'MB'}>Manitoba</MenuItem>
          <MenuItem value={'NB'}>New Brunswick</MenuItem>
          <MenuItem value={'NL'}>Newfoundland and Labrador</MenuItem>
          <MenuItem value={'NT'}>Northwest Territories</MenuItem>
          <MenuItem value={'NS'}>Nova Scotia</MenuItem>
          <MenuItem value={'NU'}>Nunavut</MenuItem>
          <MenuItem value={'ON'}>Ontario</MenuItem>
          <MenuItem value={'PE'}>Prince Edward Island</MenuItem>
          <MenuItem value={'QC'}>Quebec</MenuItem>
          <MenuItem value={'SK'}>Saskatchewan</MenuItem>
          <MenuItem value={'YT'}>Yukon</MenuItem>
  
        </Select>
    </div>
    
  );
}