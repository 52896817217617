import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
// import { useAppContext } from "../libs/contextLib";
// import { onError } from "../libs/errorLib";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import ErrorSnackBar from './ErrorSnackbar';

export default function ForgotPassword(props) {
    // const { userHasAuthenticated } = useAppContext();
    const [member_number, setMemberNumber] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [confirmReset, setConfirmReset] = useState(false);
    const [passwordType, setPasswordType] = useState('password');
    const [passwordregex, setPasswordRegex] = useState(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&=+\/\\,><\':;|_~`])\S{8,99}$/);
    const [passwordVerifyValid, setPasswordVerifyValid] = useState(true);
    const [password_verify, setPassword_verify] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useHistory();

    const closeError = () => {
      setIsErrorOpen (false);
    }

    const handleError = (inErrorMessage) => {
      setIsErrorOpen(true);
      setErrorMessage(inErrorMessage);
    }

  
    function validateReset() {
      return member_number.length > 0 && password.length > 0 && code.length > 0;
    }

    function validateForm() {
        return member_number.length
    }
  
    async function handleSubmit(event) {
      event.preventDefault();
    
        await Auth.forgotPassword(member_number);
        // userHasAuthenticated(true);
        
        // let access = await Auth.currentSession().then(res => res.getAccessToken());
        setConfirmReset(true);
        
    }

    function myPasswordHandler(e) {
      setPassword(e.target.value);
      //this.setState({ password: e.target.value });
      if (passwordregex.test(e.target.value) === true) {
        setPasswordValid(true);
        // this.setState({ passwordValid: true });
      } else {
        setPasswordValid(false);
        // this.setState({ passwordValid: false });
      }
    };
  
    function myPasswordVerifyHandler(e) {
      setPassword_verify(e.target.value);
      // this.setState({ password_verify: e.target.value });
      if (e.target.value === password) {
        setPasswordVerifyValid(true);
        // this.setState({ passwordVerifyValid: true });
      } else {
        // this.setState({ passwordVerifyValid: false });
        setPasswordVerifyValid(false);
      }
    };
  
    function passwordVerifyValidate(e) {
      if (password === password_verify) {
        return true;
      } else {
        setPasswordVerifyValid(false);
        return false;
      }
    };

    function showPassword() {
      setPasswordType('text')
      // this.setState({ passwordType: 'text' })
    }
  
    function hidePassword() {
      setPasswordType('password')
      // this.setState({ passwordType: 'password' })
    }

    async function handleReset(event) {
        event.preventDefault();
        try {
          await Auth.forgotPasswordSubmit(member_number, code, password);
          navigate.push('/Login');
        }
        catch(err){
          console.log(err.message);
          handleError(err.message);
        }
        
          
      }
  
    return (
      //<!-- HEADER NAVIGATION -->	
      <div class="title">
  
        {!confirmReset && <div class="row"><div class="column col-6">
          <h2 class="my_spp_auth_form">Reset Password</h2>
          <form method="POST" class="border-form spin-on-submit my_spp_auth_form" autocomplete="off"   onSubmit={handleSubmit}>
            {/* <!-- Note - intentionally visually different from the incorrect credentials message --> */}
  
  
            <div class="input required">
            <label for="member_number" class="bold-label ">User ID:
            </label>
            <p style={{ fontSize: '.8em' }}>This Number can be found in your verification email</p>
              <input type="text" name="member_number" class="wide-input" value={member_number} onChange={(e) => setMemberNumber(e.target.value)} />
            </div>
            {/* <div class="input required">
              <label for="password" class="bold-label ">Password:</label>
              <input type="password" name="password" class="wide-input" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div> */}
  
            <br />
            <div style={{ textalign: "right" }}>
                <button type="submit" value="Submit" class="button-green" disabled={!validateForm()} >Submit</button>
            </div>
          </form>
  
          
        </div>
        </div>}
        {confirmReset && <div class="row"><div class="column col-6">
          <h2 class="my_spp_auth_form">Enter verification code and new password</h2>
          <form method="POST" class="border-form spin-on-submit my_spp_auth_form" autocomplete="off"   onSubmit={handleSubmit}>
            {/* <!-- Note - intentionally visually different from the incorrect credentials message --> */}
  
  
            <div class="input required">
            <label for="member_number" class="bold-label ">User ID:
            </label>
            <div style={{ fontSize: '0.8em' }}>This Number can be found in your verification email</div>
              <input type="text" name="member_number" class="wide-input" disabled={true} value={member_number}  />
            </div>
            <label class="bold-label ">We need you to verify your email address. We've sent a 6 digit verification code to the email address we have on file. Please enter the verification code below</label>
            <div class="input required">
              <label for="code" class="bold-label ">Verification Code:</label>
              <input type="text" name="code" class="wide-input" value={code} onChange={(e) => setCode(e.target.value)} maxLength="6"/>
            </div>
            <label class="bold-label ">Please enter a new password</label>
            <div class="input required">
              <label for="password" style={{ display: "contents" }} class="bold-label ">Password:</label>{passwordType === 'password' && <IconButton aria-label="sort" title="Show Password" size="medium" onClick={showPassword}>
                  <VisibilityIcon color="black" fontSize="inherit" />
                </IconButton>}
                {passwordType === 'text' && <IconButton aria-label="sort" title="Hide Password" size="medium" onClick={hidePassword}>
                  <VisibilityOffIcon color="black" fontSize="inherit" />
                </IconButton>}
              <input type={passwordType} style={{ border: passwordValid ? '' : '1px solid #ff0000' }} name="password" class="wide-input" minLength="8" pattern={passwordregex} autoComplete="new-password" onChange={myPasswordHandler} />
              <div style={{ fontsize: "0.8em" }}>At least 8 characters and must include lowercase, uppercase, numbers, and a special character (^ $ * . [ ] {"{}"} ( ) ? - " ! @ # % & / \ , {"<>"} ' : ; | _ ~ ` = +)</div>
            </div>
            <div class="input required">
                <label for="password_verify" class="bold-label ">Password (verify):</label>
                <input type={passwordType} name="password_verify" style={{ border: passwordVerifyValid ? '' : '1px solid #ff0000' }} class="wide-input" onChange={myPasswordVerifyHandler} autoComplete="new-password" required />
              </div>
  
            <br />
            <div style={{ textalign: "right" }}>
                <button type="button" class="button-green" disabled={!validateReset()} onClick={handleReset} >Confirm</button>
            </div>
          </form>
  
            <ErrorSnackBar open={isErrorOpen} handleClose={closeError} message={errorMessage}/>
                
        </div>
        </div>}
  
      </div>
    )
  }