import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


class SessionEnded extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    
  }

  
  render() {
    return (
      <div>
      Session has ended
          </div>
    )
  }
}

export default SessionEnded